import useFirestoreData from "@/store/useFirestoreData";
import { DEFAULT_EMPTY_STRING } from "@/utils/utilityConstants";

import { ORGANIZATIONS_COLLECTION } from "./constants";
import {
  createFirestoreDoc,
  getFirestoreDoc,
  updateFirestoreDoc,
} from "./firestore";
import {
  type FirestoreOrganization,
  type InsertOrganization,
  type UpdateOrganization,
  type UpdateOrganizationLicense,
} from "./types/Organization";

export const getOrganizationListFromFirestoreData = () =>
  useFirestoreData.getState().organizations;

export const getOrganizationNameFromId = (id: string) =>
  getOrganizationFromId(id)?.name ?? DEFAULT_EMPTY_STRING;

export const getOrganizationFromId = (id: string) => {
  const organizationList = getOrganizationListFromFirestoreData();
  return organizationList.find((o) => o.id === id);
};

export const getOrganization = async (organizationId: string) =>
  await getFirestoreDoc<FirestoreOrganization>(
    ORGANIZATIONS_COLLECTION,
    organizationId
  );

export const addOrganization = async ({
  insertOrganization,
}: {
  insertOrganization: InsertOrganization;
}) =>
  await createFirestoreDoc<InsertOrganization>(
    ORGANIZATIONS_COLLECTION,
    insertOrganization
  );

export const updateOrganization = async ({
  id,
  ...updateOrganization
}: UpdateOrganization) =>
  await updateFirestoreDoc<typeof updateOrganization>(
    ORGANIZATIONS_COLLECTION,
    id,
    updateOrganization
  );

export const updateOrganizationLicense = async ({
  updateOrganizationLicense,
  id,
}: {
  updateOrganizationLicense: UpdateOrganizationLicense;
  id: string;
}) =>
  await updateFirestoreDoc<UpdateOrganizationLicense>(
    ORGANIZATIONS_COLLECTION,
    id,
    updateOrganizationLicense
  );
