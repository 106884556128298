import classNames from "classnames";
import React, { type FC, useMemo } from "react";

import {
  TypographyBodySmall,
  TypographyCaption,
} from "@/components/Foundations/Typography";
import { type TypographyBodySmallProps } from "@/components/Foundations/Typography/TypographyBodySmall";
import MultiValidation, {
  type InputMultiValidationProps,
} from "@/components/Molecules/MultiValidation";

import styles from "./InputContainer.module.scss";

export interface InputContainerProps {
  titleProps?: TypographyBodySmallProps;
  titleTestId?: string;
  title?: string | null;
  isDisabled?: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;
  validationData?: InputMultiValidationProps;
  helpMessage?: string;
  helpMessageTestId?: string;
}

const InputContainer: FC<InputContainerProps & { children: JSX.Element }> = ({
  children,
  title = "",
  titleProps,
  isDisabled = false,
  isRequired = false,
  validationData: { errors, testId: errorMessageTestId } = {},
  isInvalid = false,
  helpMessage = "",
  titleTestId,
  helpMessageTestId,
}) => {
  const containerStyles = classNames([
    styles.inputContainer,
    {
      [styles.disabledContainer]: isDisabled,
      [styles.invalidContainer]: isInvalid,
    },
  ]);

  const labelStyles = classNames([styles.label]);

  const helpMessageStyles = classNames([styles.helpMessage]);

  const titleMemo = useMemo(() => {
    if (!title) return "";
    if (isRequired) return `${title} *`;
    return title;
  }, [isRequired, title]);

  return (
    <div className={containerStyles}>
      {!!title && (
        <TypographyBodySmall
          value={titleMemo}
          variant="medium"
          className={labelStyles}
          testId={titleTestId}
          {...titleProps}
        />
      )}
      {children}
      {isInvalid && (
        <MultiValidation errors={errors} testId={errorMessageTestId} />
      )}
      {!isInvalid && helpMessage && (
        <TypographyCaption
          className={helpMessageStyles}
          value={helpMessage}
          testId={helpMessageTestId}
        />
      )}
    </div>
  );
};

export default InputContainer;
