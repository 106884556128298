import { redirect } from "react-router-dom";

import { isUserLoggedIn } from "@/firebase/auth";
import Pages from "@/pages/router-pages";

const rootLoader = async () => {
  const logged = await isUserLoggedIn();
  if (!logged) {
    return redirect(Pages.LOGIN);
  }
  return redirect(Pages.HOME);
};

export default rootLoader;
