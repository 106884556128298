import { type TypographyValue, type TypographyVariantObject } from "..";

type H3Props = JSX.IntrinsicElements["h3"] &
  TypographyVariantObject<"normal" | "semibold"> &
  TypographyValue;

const H3 = ({
  value = "",
  variant = "normal",
  className = "",
  testId,
  ...props
}: H3Props) => (
  <h3
    className={`text-h3 font-${variant} ${className}`}
    data-test-id={testId}
    {...props}
  >
    {value}
  </h3>
);

export default H3;
