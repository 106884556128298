import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { evalGenericBlockerNavigationConfig } from "./constants";
import {
  type BlockerNavigationModalConfig,
  type BlockerNavigationStore,
} from "./types";

const useBlockerNavigationInitialState = {
  blocker: null,
  config: {} satisfies Record<string, BlockerNavigationModalConfig>,
};

const useBlockerNavigationStore = create<BlockerNavigationStore>()(
  devtools((set, get) => ({
    ...useBlockerNavigationInitialState,
    appendConfig: (key, config = evalGenericBlockerNavigationConfig()) => {
      set((state) => ({
        config: {
          ...state.config,
          [key]: {
            ...evalGenericBlockerNavigationConfig(),
            ...config,
          },
        },
      }));
    },
    removeConfig: (key) => {
      set((state) => {
        const { [key]: _, ...rest } = state.config;
        return { config: rest };
      });
    },
  }))
);

export default useBlockerNavigationStore;
