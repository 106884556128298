// TODO: sync this types with server (cloud functions repo)

// COLLECTIONS
export const ROLES_COLLECTION = "roles";
export const USERS_COLLECTION = "users";
export const ORGANIZATIONS_COLLECTION = "organizations";
export const CASES_COLLECTION = "cases";
export const CASES_ATTACHMENTS_COLLECTION = "attachments";
export const CASES_DICOM_COLLECTION = "dicom";
export const CASES_DICOM_STORAGE_UPLOAD_PATH = "dicom/verified";
export const CASES_NIFTI_COLLECTION = "nifti";
export const SPECIALIZATION_COLLECTION = "specialization";
export const PATHOLOGIES_COLLECTION = "pathologies";
export const ORGANS_COLLECTION = "organs";
export const DISTRIBUTORS_COLLECTION = "distributors";
export const ORDERS_COLLECTION = "orders";
export const DELEGATES_COLLECTION = "delegates";
export const INVITATIONS_COLLECTION = "invitations";
export const LOGS_COLLECTION = "logs";
export const COUNTRIES_COLLECTION = "countries";
export const NOTIFICATIONS_COLLECTION = "notifications";
export const GUIDELINES_FILE_COLLECTION = "guidelines_files";
export const GUIDELINES_VIDEO_COLLECTION = "guidelines_videos";
export const SYSTEM_CONFIGURATION_COLLECTION = "system_configurations";

// SYSTEM CONFIGS
export const MODULE_CONFIG = "moduleConfig";
export const DASHBOARD_CONFIG_PREFIX = "dashboardConfig";
export const CASE_STEP_FOUR_SURVEY_LINK = "caseStepFourSurveyLink";

// HOOK CONSTANTS
export const DEFAULT_FIREBASE_HOOKS_QUERY_LIMIT = 10;
export const DEFAULT_HOME_PAGE_TABLES_FIREBASE_QUERY_LIMIT = 5;
export const DEFAULT_USERS_EXPIRY_EXTENSIONS_DAYS = 180;
