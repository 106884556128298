import {
  type DocumentSnapshot,
  type QueryDocumentSnapshot,
} from "firebase/firestore";

const castDocIntoType = <T extends { id: string }>(
  docSnap: QueryDocumentSnapshot | DocumentSnapshot
) => {
  const doc: T = { ...(docSnap.data() as T), id: docSnap.id };

  return doc;
};
export default castDocIntoType;
