import { callApi } from "./apiClient";
import { SEND_NOTIFICATION_MESSAGES_API } from "./constants";

export interface sendNotificationMessagesProps {
  body: string;
  subject: string;
  recaptcha: string;
}

export const sendNotificationMessages = async ({
  body,
  subject,
  recaptcha,
}: sendNotificationMessagesProps) =>
  await callApi({
    method: "POST",
    url: `/${SEND_NOTIFICATION_MESSAGES_API}`,
    data: {
      body,
      subject,
      recaptcha,
    },
  });
