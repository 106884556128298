import {
  DEFAULT_USERS_EXPIRY_EXTENSIONS_DAYS,
  USERS_COLLECTION,
} from "./constants";
import { getFirestoreDoc, updateFirestoreDoc } from "./firestore";
import { type UpdateUser, type User } from "./types/User";

export const getUser = async (id: User["id"]) =>
  await getFirestoreDoc<User>(USERS_COLLECTION, id);

export const updateUser = async ({ id, ...updateUser }: UpdateUser) =>
  await updateFirestoreDoc<typeof updateUser>(USERS_COLLECTION, id, updateUser);

export const updateUserPasswordExpiry = async ({
  id,
  expiryExtensionDays = DEFAULT_USERS_EXPIRY_EXTENSIONS_DAYS,
}: {
  id: User["id"];
  expiryExtensionDays?: number;
}) => {
  const resetPasswordDate = new Date();
  resetPasswordDate.setDate(resetPasswordDate.getDate() + expiryExtensionDays);
  const resetPassword = resetPasswordDate.getTime();
  return await updateFirestoreDoc<Partial<User>>(USERS_COLLECTION, id, {
    resetPassword,
  });
};
