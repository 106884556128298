import { type DoctorRelation } from "@/firebase/types/User";
import {
  DEFAULT_CONCATENATE_SEPARATOR,
  DEFAULT_EMPTY_STRING,
} from "@/utils/utilityConstants";

import { isStringNotEmpty } from "./typeCheck";

export const identity = <T>(value: T) => value;

export const first = <T>(arr: T[] | null | undefined): T | undefined => {
  if (arr && arr.length > 0) {
    return arr[0];
  }
  return undefined;
};

export const checkIntersection = <T>(arr1: T[], arr2: T[]) => {
  return arr1.some((item) => arr2.includes(item));
};

export const removeNullUndefined = <T>(arr: T[]) => {
  return arr.filter((item) => item !== null && item !== undefined);
};

interface StringConcatenateOptions {
  separator?: string;
  removeEmptyStrings?: boolean;
}

export const concatenateStrings = (
  strings: string[],
  options?: StringConcatenateOptions
) => {
  const internalOptions = {
    separator: DEFAULT_CONCATENATE_SEPARATOR,
    ...options,
  };

  let internalStrings = strings;
  if (internalOptions.removeEmptyStrings) {
    internalStrings = internalStrings.filter(isStringNotEmpty);
  }
  return internalStrings.join(internalOptions.separator);
};

export const mapObjectIntoSelectOptions = <
  T extends Record<string, any>,
  K extends keyof T
>(
  elements: T[],
  keyLabel: K,
  keyValue: K
) =>
  elements.map((element) => ({
    label: element[keyLabel],
    value: element[keyValue],
  }));

export const generateRandomString = () => Math.random().toString(36).slice(-10);

export const generateRandomPhoneNumber = () =>
  `+39333${Math.floor(Math.random() * 900000) + 100000}`;

export const downloadFromUrl = (dataUrl: string, filename: string) => {
  const link = document.createElement("a");
  link.href = dataUrl;
  link.target = "_blank";
  link.download = filename;
  link.referrerPolicy = "no-referrer";
  link.rel = "noopener noreferrer";
  link.click();
};

export const capitalizeString = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const isEmptyObject = (obj: object) => Object.keys(obj).length === 0;

export const findOptionByValue = <T extends Record<string, any>>(
  options: T[],
  value: string
) => options.find((option) => option.value === value);

export const findOptionByLabel = <T extends Record<string, any>>(
  options: T[],
  label: string
) => options.find((option) => option.label === label);

export const findOptionByLabelOrValue = <T extends Record<string, any>>(
  options: T[],
  labelOrValue: string
) => {
  const optionByLabel = findOptionByLabel(options, labelOrValue);
  if (optionByLabel) {
    return optionByLabel;
  }
  return findOptionByValue(options, labelOrValue);
};

export const arrayToBooleanObject = (array: string[]): Record<string, true> =>
  array.reduce<Record<string, true>>((acc, currentValue) => {
    acc[currentValue] = true;
    return acc;
  }, {});

export const mapStringIdInObjectId = (ids: string[]) =>
  ids.map((id) => ({ id }));

export const evalDoctorFullName = (
  doctor?: Pick<DoctorRelation, "lastName" | "firstName">
) => {
  if (!doctor) return DEFAULT_EMPTY_STRING;
  return `${doctor.lastName} ${doctor.firstName}`;
};

export const renameFile = (originalFile: File, newName: string) =>
  new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });

export const JSONStringifyFormatted = (obj: object) =>
  JSON.stringify(obj, null, 4);
