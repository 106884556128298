import { type MutationFunction } from "@tanstack/query-core";
import { useMutation as useTanstackMutation } from "@tanstack/react-query";
import { type UseMutationOptions } from "@tanstack/react-query/src/types";
import i18n from "i18next";
import { useCallback, useState } from "react";
import { type Id, toast } from "react-toastify";

import { type ToastMutationOptions } from "@/hooks/useMutation/types";
import {
  invokeToastError,
  invokeToastLoading,
  invokeToastSuccess,
} from "@/utils/toastify";
import { isString } from "@/utils/typeCheck";
import { DEFAULT_EMPTY_STRING } from "@/utils/utilityConstants";

export type UseMutationProps<
  TData = unknown,
  TVariables = void,
  TError extends Error = Error
> = Omit<
  UseMutationOptions<TData, TError, TVariables>,
  "mutationFn" | "onMutate" | "onSettled" | "onSuccess" | "onError"
> &
  ToastMutationOptions<TData, TError>;

const useMutation = <
  TData = unknown,
  TVariables = void,
  TError extends Error = Error
>(
  mutationFn: MutationFunction<TData, TVariables>,
  {
    hideLoadingToast,
    hideErrorToast,
    hideSuccessToast,
    successDescription,
    errorDescription,
    successTestId,
    errorTestId,
    loadingDescription = i18n.t(`generic.loading.description`),
    autoCloseErrorToast,
    ...tanstackMutationOptions
  }: UseMutationProps<TData, TVariables, TError>
) => {
  const [toastLoadingId, setToastLoadingId] = useState<Id>();
  const dismissLoadingHandler = useCallback(
    () => toast.dismiss(toastLoadingId),
    [toastLoadingId]
  );

  return useTanstackMutation({
    mutationFn,
    ...tanstackMutationOptions,
    onMutate: !hideLoadingToast
      ? () => {
          const toastId = invokeToastLoading(
            {
              description: loadingDescription,
              disableCloseButton: true,
            },
            { autoClose: false }
          );
          setToastLoadingId(toastId);
        }
      : undefined,
    onSettled: !hideLoadingToast
      ? () => {
          dismissLoadingHandler();
        }
      : undefined,
    onSuccess: !hideSuccessToast
      ? (data) => {
          let description = DEFAULT_EMPTY_STRING;
          if (isString(successDescription)) {
            description = successDescription;
          } else {
            description = successDescription(data);
          }

          invokeToastSuccess({
            description,
            testId: successTestId,
          });
        }
      : undefined,
    onError: !hideErrorToast
      ? (error) => {
          let description = DEFAULT_EMPTY_STRING;
          if (isString(errorDescription)) {
            description = errorDescription;
          } else {
            description = errorDescription(error);
          }

          invokeToastError(
            {
              description,
              testId: errorTestId,
            },
            {
              autoClose: autoCloseErrorToast,
            }
          );
        }
      : undefined,
  });
};

export default useMutation;
