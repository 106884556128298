import { Controller, type FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Checkbox from "@/components/Atoms/MultiSelectButton/Checkbox";
import { TypographyBody } from "@/components/Foundations/Typography";
import { type EntityFormControllerProps } from "@/entity/types";

const FormPrivacyController = <TForm extends FieldValues>({
  control,
  errors,
  nameField,
  testId = "form",
}: EntityFormControllerProps<TForm>) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={nameField}
      control={control}
      render={({ field: { disabled, onChange, value } }) => (
        <Checkbox
          validationData={{
            errors,
            testId: `${testId}-validation`,
          }}
          label={
            <TypographyBody value={t("registration.form.checkboxes.privacy")} />
          }
          checked={value}
          onChange={onChange}
          testId={testId}
        />
      )}
    />
  );
};

export default FormPrivacyController;
