import classNames from "classnames";
import { type FC, useEffect, useRef } from "react";
import { RiCheckLine, RiSubtractLine } from "react-icons/ri";

import MultiSelectButton, {
  type MultiSelectButtonProps,
} from "@/components/Atoms/MultiSelectButton";

import styles from "./Checkbox.module.scss";

export const Checkbox: FC<
  MultiSelectButtonProps & {
    checked: boolean;
    onChange: (v: boolean) => void;
    indeterminate?: boolean;
  }
> = ({
  label,
  checked,
  indeterminate = false,
  onChange,
  disabled,
  testId,
  isInvalid = false,
  validationData: { errors, testId: errorMessageTestId } = {},
}) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) ref.current.indeterminate = !checked && indeterminate;
  }, [checked, indeterminate]);

  const checkboxWrapperStyle = classNames([
    styles.checkboxWrapper,
    {
      [styles.disabled]: disabled,
      [styles.checked]: checked,
      [styles.indeterminate]: indeterminate,
      [styles.invalid]: isInvalid,
    },
  ]);

  return (
    <MultiSelectButton
      label={label}
      disabled={disabled}
      testId={testId}
      isInvalid={isInvalid}
      validationData={{
        errors,
        testId: errorMessageTestId,
      }}
    >
      <div className={checkboxWrapperStyle}>
        <input
          ref={ref}
          type="checkbox"
          className={styles.checkbox}
          disabled={disabled}
          defaultChecked={checked}
          onClick={() => onChange(!checked)}
        />
        {!checked && indeterminate && <RiSubtractLine />}
        {checked && <RiCheckLine />}
      </div>
    </MultiSelectButton>
  );
};

export default Checkbox;
