import * as Sentry from "@sentry/react";
import Axios, {
  type AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
} from "axios";

import { getSerializedToken } from "@/firebase/auth";
import { JSONStringifyFormatted } from "@/utils/utilityFunction";

const apiClient = Axios.create({
  baseURL: import.meta.env.VITE_API_FUNCTIONS_HTTP_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(async (config) => {
  const token = await getSerializedToken();
  if (token) config.headers.Authorization = `Bearer ${token ?? ""}`;

  return config;
});

export interface InternalError extends Error {
  code: string;
}

export const callApi = async <T = any>(
  config: AxiosRequestConfig
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await apiClient(config);
    return response.data;
  } catch (error) {
    Sentry.captureException(error, {
      extra: { jsonFormatted: JSONStringifyFormatted({ error, config }) },
    });
    const axiosError = error as AxiosError<{ error: InternalError }>;
    if (axiosError.response?.data.error) throw axiosError.response.data.error;
    throw error;
  }
};
