import {
  and,
  collection,
  limit,
  or,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";

import { db } from "@/firebase/firebase.config";

import { type GetFirestoreDocsOptions } from "../types";

const prepareQuery = ({
  collectionName,
  wheres: w,
  limitAmount,
  orderByData,
  startAtCursor,
}: GetFirestoreDocsOptions & {
  collectionName: string;
}) => {
  const collectionRef = collection(db, collectionName);
  let q = query(collectionRef);

  if (w?.combineAndOrs) {
    q = query(
      q,
      and(
        ...w.andWheres.map((w1) => where(...w1)),
        ...w.orWheres.map((w1) => or(...w1.map((w2) => where(...w2))))
      )
    );
  } else if (w?.combineOrAnds) {
    q = query(
      q,
      or(
        ...w.orWheres.map((w1) => where(...w1)),
        ...w.andWheres.map((w1) => and(...w1.map((w2) => where(...w2))))
      )
    );
  } else {
    if (w?.andWheres && w.andWheres.length > 0) {
      q = query(q, ...w.andWheres.map((w1) => where(...w1)));
    }

    if (w?.orWheres && w.orWheres.length > 0) {
      q = query(q, or(...w.orWheres.map((w1) => where(...w1))));
    }
  }

  if (w?.fixedAndWheres) {
    q = query(q, ...w.fixedAndWheres.map((w1) => where(...w1)));
  }

  if (w?.fixedOrWheres) {
    q = query(q, or(...w.fixedOrWheres.map((w1) => where(...w1))));
  }

  if (limitAmount) {
    q = query(q, limit(limitAmount));
  }

  if (orderByData) {
    if (Array.isArray(orderByData)) {
      q = query(
        q,
        ...orderByData.map((obd) => orderBy(obd.fieldPath, obd.direction))
      );
    } else {
      q = query(q, orderBy(orderByData.fieldPath, orderByData.direction));
    }
  }

  if (startAtCursor) {
    q = query(q, startAfter(startAtCursor));
  }

  return q;
};

export default prepareQuery;
