import { useCallback, useRef } from "react";

const useModalRef = () => {
  const ref = useRef<HTMLDialogElement>(null);

  const close = useCallback(() => ref.current?.close(), []);
  const open = useCallback(() => ref.current?.show(), []);

  return {
    ref,
    close,
    open,
  };
};

export default useModalRef;
