import { Route } from "react-router-dom";

import { concatenateStrings } from "@/utils/utilityFunction";

interface EntityRouteProps {
  basePath: string;
  BaseComponent: JSX.Element;
  ListComponent: JSX.Element;
  AddComponent: JSX.Element;
  EditComponent: JSX.Element;
}
const EntityRoute = ({
  basePath,
  BaseComponent,
  AddComponent,
  EditComponent,
  ListComponent,
}: EntityRouteProps) => {
  return (
    <Route element={BaseComponent}>
      <Route
        path={concatenateStrings([basePath, "add"], {
          separator: "/",
        })}
        element={AddComponent}
      />
      <Route
        element={EditComponent}
        path={concatenateStrings([basePath, ":id"], {
          separator: "/",
        })}
      />
      <Route path={basePath} element={ListComponent} />
    </Route>
  );
};

export default EntityRoute;
