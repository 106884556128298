import { type TypographyValue, type TypographyVariantObject } from "..";

type TypographyBodyProps = JSX.IntrinsicElements["span"] &
  TypographyVariantObject<"normal" | "medium" | "semibold"> &
  TypographyValue;

const TypographyBody = ({
  value = "",
  variant = "normal",
  className = "",
  testId,
  ...props
}: TypographyBodyProps) => (
  <span
    className={`text-body font-${variant} ${className}`}
    data-test-id={testId}
    {...props}
  >
    {value}
  </span>
);

export default TypographyBody;
