import { useMemo } from "react";

import { RolesMapping } from "@/firebase/types/Role";
import useUserStore from "@/store/useUserStore";

const useIsDemoDoctor = () => {
  const { user } = useUserStore();

  return useMemo(
    () => user?.role?.id === RolesMapping.DemoDoctor.id,
    [user?.role?.id]
  );
};

export default useIsDemoDoctor;
