import { type LoaderFunctionArgs, redirect } from "react-router-dom";

import { checkAssistant } from "@/api/assistant/checkAssistant";
import { type AssistantData } from "@/api/assistant/types";
import { isUserLoggedIn, loginInAnonymously } from "@/firebase/auth";
import Pages, { formatDynamicPage } from "@/pages/router-pages";

const assistantDicomLoader = async ({
  request,
}: LoaderFunctionArgs): Promise<AssistantData | Response> => {
  const url = new URL(request.url);
  const caseId = url.searchParams.get("caseId");
  const token = url.searchParams.get("token");

  if (!caseId || !token) {
    return redirect(Pages.FOUR_ZERO_FOUR);
  }

  const logged = await isUserLoggedIn();

  if (logged) {
    return redirect(formatDynamicPage(Pages.CASE_EDIT, caseId));
  }
  /**
   * AutoLogin Anonymously
   */
  await loginInAnonymously();

  return await checkAssistant(caseId, token);
};

export default assistantDicomLoader;
