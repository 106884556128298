import "./index.css";
import "./i18n";
import "dayjs/locale/it";
import "dayjs/locale/en-gb";
import "dayjs/locale/de";
import "dayjs/locale/fr";
import "dayjs/locale/es";
import "react-toastify/dist/ReactToastify.css";

import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import ReactDOM from "react-dom/client";
import { IconContext } from "react-icons";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import ContactUsModalForm from "@/components/ContactUsModal/ContactUsModalForm";
import router from "@/pages/router";
import { applicationQueryClient } from "@/react-query";

import { DEFAULT_ICON_SIZE } from "./theme/theme-constants";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  enabled: Boolean(+import.meta.env.VITE_SENTRY_ENABLED),
  debug: process.env.NODE_ENV === "development",
  release: APP_VERSION,
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <QueryClientProvider client={applicationQueryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <IconContext.Provider value={{ size: `${DEFAULT_ICON_SIZE}px` }}>
          <RouterProvider router={router} />
          <ToastContainer newestOnTop />
          <ContactUsModalForm />
        </IconContext.Provider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
