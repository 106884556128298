import classNames from "classnames";
import { type FC } from "react";
import { RiInformationLine } from "react-icons/ri";

import { TypographyCaption } from "@/components/Foundations/Typography";
import { HELP_MESSAGE_ICON_SIZE } from "@/theme/theme-constants";

import styles from "./HelpMessage.module.scss";

interface HelpMessageProps {
  label: string;
  iconPosition?: "left" | "right" | null;
  variant?: "regular" | "invalid" | "disabled" | "info";
  testId?: string;
}

const HelpMessage: FC<HelpMessageProps> = ({
  label = "",
  iconPosition = "left",
  variant = "regular",
  testId,
}) => {
  const helpMessageContainerStyle = classNames([
    styles.container,
    styles[variant],
  ]);

  return (
    <div className={helpMessageContainerStyle}>
      {iconPosition === "left" && (
        <RiInformationLine
          className={styles.label}
          size={HELP_MESSAGE_ICON_SIZE}
        />
      )}
      <TypographyCaption
        value={label}
        className={styles.label}
        testId={testId}
      />
      {iconPosition === "right" && (
        <RiInformationLine
          className={styles.label}
          size={HELP_MESSAGE_ICON_SIZE}
        />
      )}
    </div>
  );
};

export default HelpMessage;
