import i18n from "@/i18n";

export enum Locale {
  enGB = "en-GB",
  enUS = "en-US",
  itIT = "it-IT",
  deDE = "de-DE",
  frFR = "fr-FR",
  esES = "es-ES",
}

export enum Currency {
  USD = "USD",
  GBP = "GBP",
  EUR = "EUR",
}

/**
 * Returns the locale or the fallback if the locale is not supported.
 *
 * @param {string} localeToCheck - The locale to check.
 * @return {Locale}
 */
export const validLocaleOrFallback = (
  localeToCheck: string,
  fallback = Locale.enUS
): Locale => {
  // * If the language is supported
  if (Object.values(Locale).includes(localeToCheck as Locale))
    return localeToCheck as Locale;

  // * If the language is not supported
  return fallback;
};

export const formatDateToLocal = (date: Date) => {
  return new Intl.DateTimeFormat(i18n.language).format(date);
};

export const formatCurrencyToLocal = (value: number) => {
  let currency = "";
  switch (i18n.language as Locale) {
    case Locale.enUS:
      currency = Currency.USD;
      break;
    case Locale.enGB:
      currency = Currency.GBP;
      break;
    default:
      currency = Currency.EUR;
  }
  return new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency,
  }).format(value);
};

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
