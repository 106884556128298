import classNames from "classnames";

import { type BaseButtonProps } from "@/components/Atoms/Button/types";
import Spinner from "@/components/Atoms/Spinner";

import styles from "./TextButton.module.scss";
import { getTextButtonSizeVariantComponent } from "./utils/text-button-size-variants";

export interface TextButtonProps extends BaseButtonProps {
  variant?: "primary" | "secondary" | "secondaryOnDark" | "tertiary" | "danger";
  size?: "small" | "medium" | "large";
}

const TextButton = ({
  buttonProps: { className = "", ...buttonProps } = {},
  label,
  leftIcon,
  rightIcon,
  variant = "primary",
  size = "medium",
  alignment = "center",
  isLoading = false,
  isLoadingCursor = false,
  isActive,
  testId,
}: TextButtonProps) => {
  const TextButtonTypography = getTextButtonSizeVariantComponent(size);
  const buttonStyle = classNames(
    [
      "flex items-center gap-2 bg-transparent",
      styles.button,
      styles[variant],
      styles[alignment],
      className,
    ],
    { [styles.active]: isActive, [styles.isLoadingCursor]: isLoadingCursor }
  );
  return (
    <button
      className={buttonStyle}
      data-test-id={testId}
      type="button"
      {...buttonProps}
    >
      {isLoading ? <Spinner size="small" /> : leftIcon}
      {label && <TextButtonTypography className="flex-1" value={label} />}
      {rightIcon}
    </button>
  );
};
export default TextButton;
