import {
  H3,
  TypographyButton,
  TypographyCaption,
} from "@/components/Foundations/Typography";

import { type TextButtonProps } from "../TextButton";

const TEXT_BUTTON_SIZE_VARIANT_MAP = {
  small: TypographyCaption,
  medium: TypographyButton,
  large: H3,
} as const;

export const getTextButtonSizeVariantComponent = (
  variant: TextButtonProps["size"]
) => TEXT_BUTTON_SIZE_VARIANT_MAP[variant ?? "medium"];
