import {
  confirmPasswordReset,
  EmailAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  signInAnonymously,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  type User as UserFirebase,
} from "firebase/auth";
import { type FirestoreError } from "firebase/firestore";

import { extendPasswordExpirationAfterResetPassword } from "@/api/extendPasswordExpirationAfterResetPassword";
import { userCreate } from "@/api/userCreate";
import { userSignup } from "@/api/userSignup";
import {
  type FirebaseLoginAuthError,
  type FirebaseSignUpAuthError,
  type FirebaseVerifyPasswordAuthError,
} from "@/firebase/types/AuthErrors";
import { type UserPrivileges } from "@/firebase/types/Privileges";
import { type PopulatedRole } from "@/firebase/types/Role";
import { getModulesAndFeaturesPrivilegesForRole } from "@/firebase/utils";
import useUserStore from "@/store/useUserStore";

import { ROLES_COLLECTION, USERS_COLLECTION } from "./constants";
import { auth } from "./firebase.config";
import { getFirestoreDoc } from "./firestore";
import { type ReCaptchaError } from "./types/Errors/ReCaptchaErrors";
import { type InsertUser, type User } from "./types/User";
import { updateUserPasswordExpiry } from "./user";

export const getToken = async () => {
  try {
    if (!auth.currentUser) return;
    return await auth.currentUser.getIdTokenResult(true);
  } catch (error) {
    console.error(error); // TODO: alert error
    throw error;
  }
};

export const getSerializedToken = async () => {
  try {
    if (!auth.currentUser) return;
    return await auth.currentUser.getIdToken(true);
  } catch (error) {
    console.error(error); // TODO: alert error
    throw error;
  }
};

export const registerUser = async ({
  newUser,
  password,
  recaptcha,
}: {
  newUser: InsertUser;
  password: string;
  recaptcha: string;
}) => {
  try {
    await userSignup(newUser, password, recaptcha);
    return newUser;
  } catch (error) {
    throw error as FirebaseSignUpAuthError | ReCaptchaError;
  }
};

export const addUser = async (newUser: InsertUser) => {
  try {
    const userId = await userCreate(newUser);
    return userId;
  } catch (error) {
    throw error as FirebaseSignUpAuthError;
  }
};

export const getUserModulesAndFeaturesPrivileges = async (): Promise<{
  role: PopulatedRole;
  privileges: UserPrivileges;
}> => {
  const token = await getToken();
  const roleIdFromCustomClaims = token?.claims.id as string | undefined;
  if (!roleIdFromCustomClaims) throw new Error("No Claims");

  const role = await getFirestoreDoc<PopulatedRole>(
    ROLES_COLLECTION,
    roleIdFromCustomClaims
  );
  if (!role) throw new Error("No PopulatedRole");

  const privileges = await getModulesAndFeaturesPrivilegesForRole(role);

  if (!privileges) {
    throw new Error("No Privileges Config");
  }

  return {
    role,
    privileges,
  };
};

export const getCurrentUser = async () => {
  if (!auth.currentUser) {
    throw new Error("afterLogin/user-not-found");
  }
  const userInDB = await getFirestoreDoc<User>(
    USERS_COLLECTION,
    auth.currentUser.uid
  );

  if (!userInDB) throw new Error("afterLogin/user-not-found");

  return userInDB;
};

export const isUserActive = async (user: UserFirebase) => {
  if (!user.emailVerified)
    throw new Error("afterLogin/waiting-email-verification");

  let userInDB: User;
  try {
    userInDB = await getFirestoreDoc<User>(USERS_COLLECTION, user.uid);
  } catch {
    throw new Error("afterLogin/user-not-found");
  }
  if (userInDB.status === "pending")
    throw new Error("afterLogin/waiting-user-approving");
  if (userInDB.status === "disabled")
    throw new Error("afterLogin/user-disabled");
  return true;
};

export const loginInAnonymously = async () => {
  try {
    await signInAnonymously(auth);
  } catch (error) {
    throw new Error((error as FirebaseLoginAuthError).code);
  }
};

export const loginUser = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  let userCredential: Awaited<ReturnType<typeof signInWithEmailAndPassword>>;
  try {
    userCredential = await signInWithEmailAndPassword(auth, username, password);
  } catch (error) {
    throw error as FirebaseLoginAuthError;
  }

  if (await isUserActive(userCredential.user)) return userCredential.user;
};

export const logoutUser = async () => {
  try {
    useUserStore.getState().resetUserState();
    await signOut(auth);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const confirmThePasswordReset = async ({
  oobCode,
  uid,
  newPassword,
}: {
  oobCode: string;
  uid: string;
  newPassword: string;
}) => {
  if (!oobCode && !newPassword) return;
  await confirmPasswordReset(auth, oobCode, newPassword);
  return await extendPasswordExpirationAfterResetPassword({
    oobCode,
    userId: uid,
  });
};

export const isUserLoggedIn = async (): Promise<boolean> => {
  try {
    await new Promise((resolve, reject) =>
      onAuthStateChanged(
        auth,
        async (user) => {
          try {
            if (user) {
              await isUserActive(user);
              resolve(user);
            } else throw new Error();
          } catch (error) {
            reject(new Error("No user logged in"));
          }
        },
        // Prevent console error
        (error) => reject(error)
      )
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const verifyPasswordForCurrentUser = async (password: string) => {
  if (!auth.currentUser?.email) throw new Error("Invalid user");
  const credential = EmailAuthProvider.credential(
    auth.currentUser.email,
    password
  );
  return await reauthenticateWithCredential(auth.currentUser, credential);
};

export const updateCurrentUserPassword = async ({
  oldPassword,
  newPassword,
}: {
  oldPassword: string;
  newPassword: string;
}) => {
  if (!auth.currentUser?.email || !auth.currentUser?.uid)
    throw new Error("Invalid user");

  try {
    await verifyPasswordForCurrentUser(oldPassword);
    await updatePassword(auth.currentUser, newPassword);
  } catch (error) {
    throw error as FirebaseVerifyPasswordAuthError;
  }

  try {
    return await updateUserPasswordExpiry({ id: auth.currentUser.uid });
  } catch (error) {
    throw error as FirestoreError;
  }
};
