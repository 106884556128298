import { DevTool } from "@hookform/devtools";
import { type Control, type FieldValues } from "react-hook-form";

const FormDevTool = <T extends FieldValues>({
  control,
}: {
  control: Control<T>;
}) => {
  if (import.meta.env.MODE !== "development") return null;
  return <DevTool control={control} />;
};

export default FormDevTool;
