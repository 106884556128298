import * as Sentry from "@sentry/react";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

import {
  getCurrentUser,
  getUserModulesAndFeaturesPrivileges,
} from "@/firebase/auth";
import { type UserPrivileges } from "@/firebase/types/Privileges";
import { type PopulatedRole } from "@/firebase/types/Role";
import { type PopulatedUser } from "@/firebase/types/User";
import { populateUserById } from "@/firebase/utils";

interface UserStoreState {
  privileges: UserPrivileges | null;
  role: PopulatedRole | null;
  user: PopulatedUser | null;
}

interface UserStoreActions {
  fulfillUserState: () => Promise<void>;
  resetUserState: () => void;
}

const useUserStoreInitialState: UserStoreState = {
  privileges: null,
  role: null,
  user: null,
};

const useUserStore = create(
  subscribeWithSelector<UserStoreState & UserStoreActions>(
    (set, get, store) => ({
      ...useUserStoreInitialState,
      resetUserState() {
        set(useUserStoreInitialState);
      },
      fulfillUserState: async () => {
        const [roleModules, user] = await Promise.all([
          getUserModulesAndFeaturesPrivileges(),
          getCurrentUser(),
        ]);
        const populatedUser = await populateUserById(user.id);
        set({ ...roleModules, user: populatedUser });
        Sentry.setUser({
          email: populatedUser.email,
          id: populatedUser.id,
          ip_address: "{{auto}}",
        });
      },
    })
  )
);

export default useUserStore;
