import { type DefaultOptions, keepPreviousData } from "@tanstack/react-query";
import XRegExp from "xregexp";

import { type WheresParameters } from "@/firebase/types/utils";

export const DEFAULT_CONCATENATE_SEPARATOR = " ";
export const DEFAULT_COMMA_SEPARATOR = ", ";

export const DEFAULT_EMPTY_STRING = "";
export const DEFAULT_UNDERSCORE = "_";
export const DEFAULT_TABLE_LINK_WIDTH = 56;

const DEFAULT_CRYPTED_STRING_CHAR_NUM = 16;
const DEFAULT_CRYPTED_STRING_CHAR = "*";
export const DEFAULT_CRYPTED_STRING = "".padStart(
  DEFAULT_CRYPTED_STRING_CHAR_NUM,
  DEFAULT_CRYPTED_STRING_CHAR
);

const DEFAULT_CRYPTED_FIRSTNAME_LASTNAME_CHAR_NUM = 8;

export const DEFAULT_CRYPTED_SHORT = "".padStart(
  DEFAULT_CRYPTED_FIRSTNAME_LASTNAME_CHAR_NUM,
  DEFAULT_CRYPTED_STRING_CHAR
);

export const DEFAULT_PAGINATION_PAGE = 1;

export const DEFAULT_RATING_STARS_COUNT = 5;

export const DEFAULT_LANGUAGE_FALLBACK = "en-US";

export const DEFAULT_USE_QUERY_STALE_TIME = 1000 * 60 * 5; // 5 minutes

export const DEFAULT_USE_QUERY_SETTINGS: DefaultOptions["queries"] = {
  refetchOnWindowFocus: false,
  staleTime: DEFAULT_USE_QUERY_STALE_TIME,
  placeholderData: keepPreviousData,
};

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const INTEGER_NUMBER_REGEX = /^\d+$/;

export const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

export const SET_FORM_VALUE_DIRTY_REVALIDATE_SETTING = {
  shouldDirty: true,
  shouldValidate: true,
};

export const MIN_PASSWORD_LENGTH = 8;

export const ONE_UPPERCASE_LETTER_REGEX = /(?=.*[A-Z])/;

export const ONE_LOWER_CASE_REGEX = /(?=.*[a-z])/;

export const ONE_NUMBER_LETTER_REGEX = /(?=.*\d)/;

/*
^(?=.*\p{Letter})[\p{Letter}'\p{Mark}\s]+$

^ - Questo simbolo indica l'inizio della stringa.

[\p{Letter}\p{Mark}\s']+ - Questa è una classe di caratteri che corrisponde a qualsiasi carattere tra parentesi quadre.
                            \p{Letter} corrisponde a qualsiasi lettera in qualsiasi lingua.
                            \p{Mark} corrisponde a qualsiasi segno diacritico.
                            \s corrisponde a qualsiasi spazio bianco.
                            ' corrisponde al carattere apostrofo.
                            + significa che la classe di caratteri può ripetersi uno o più volte.

$ - Questo simbolo indica la fine della stringa.

(?=.*\p{Letter}) - Questa è un'asserzione positiva in avanti.
                   Controlla se c'è almeno una lettera nella stringa senza consumare caratteri.
                   . corrisponde a qualsiasi carattere tranne il carattere di nuova riga.
                   * significa che il carattere può ripetersi zero o più volte.
*/
export const ONLY_LETTERS_AND_SPACES_REGEX = XRegExp.tag()`^(?=.*\p{Letter})[\p{Letter}\p{Mark}\s']+$`;

export const MAX_INPUT_LENGTH = 100;

export const MIN_INPUT_DATE = new Date(Date.UTC(1900, 0, 1));

// * https://developers.google.com/recaptcha/docs/faq?hl=it#id-like-to-run-automated-tests-with-recaptcha.-what-should-i-do
export const TEST_RECAPTCHA_SITE_KEY =
  "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
export const TEST_RECAPTCHA_SECRET_KEY =
  "6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe";

export const RECAPTCHA_SITE_KEY = window.Cypress
  ? TEST_RECAPTCHA_SITE_KEY
  : (import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY as string);

export const DEFAULT_SNAPSHOT_LISTENER = true;

export const TABLE_SEARCH_PARAM = "q";

export const DEFAULT_USER_DEMO_CASES = 1;

export const DEFAULT_NOT_DEMO_USER_CASES = [
  "isDemoUserCaseDraft" as const,
  "==",
  false as const,
] satisfies WheresParameters[number];

export const I18N_NAMESPACES = {
  ERRORS: "errors",
  SPECIALIZATIONS: "specializations",
  FIREBASE_STORAGE: "firebase-storage",
} as const;
