import i18next from "i18next";

export const getErrorByCode = (
  errorCode: string,
  translationErrorPath: string
): {
  message: string;
  types: Record<string, string>;
} => {
  const errorMessage = i18next.t(`${translationErrorPath}.${errorCode}`);
  return {
    message: errorMessage,
    types: {
      [errorCode]: errorMessage,
    },
  };
};
