import { normalizeImageUrl } from "@/utils/image";

export const LOGIN_PAGE_IMAGE_PATH = normalizeImageUrl(
  "/images/covers/LoginPage.png"
);

export const REGISTRATION_PAGE_IMAGE_PATH = normalizeImageUrl(
  "/images/covers/RegistrationPage.png"
);

export const DOCTOR_NO_CASES_IMAGE_PATH = normalizeImageUrl(
  "/images/DoctorNoCases.png"
);

export const BETWEEN_STEP1_AND_STEP2_IMAGE_PATH = normalizeImageUrl(
  "/images/Between_Step1andStep2.png"
);

export const SURGICAL_TEAM_IMAGE_PATH = normalizeImageUrl(
  "/images/SurgicalTeam.png"
);

export const DICOM_SENT_IMAGE_PATH = normalizeImageUrl("/images/DICOMSent.png");

export const ERROR_403_IMAGE_PATH = normalizeImageUrl("/images/Error403.png");

export const ERROR_404_IMAGE_PATH = normalizeImageUrl("/images/Error404.png");

export const MEDICS_LOGO_ON_DARK_IMAGE_PATH = normalizeImageUrl(
  "/images/logos/Medics-Logo-On-Dark.svg"
);

export const MEDICS_LOGO_ON_LIGHT_IMAGE_PATH = normalizeImageUrl(
  "/images/logos/Medics-Logo-On-Light.svg"
);

export const MEDICS_LOGO_ON_DARK_SMALL_IMAGE_PATH = normalizeImageUrl(
  "/images/logos/Medics-Logo-On-Dark-Small.svg"
);
