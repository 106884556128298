import { useEffect } from "react";

import useBlockerNavigationStore from "@/store/useBlockerNavigationStore";
import { type AppendBlockerNavigationConfig } from "@/store/useBlockerNavigationStore/types";

const useBlockerNavigation = (
  shouldBlockNavigation: boolean,
  config: AppendBlockerNavigationConfig
) => {
  const { appendConfig, removeConfig } = useBlockerNavigationStore();

  useEffect(() => {
    if (shouldBlockNavigation)
      appendConfig(config.key, { ...config, shouldBlockNavigation });
    else removeConfig(config.key);

    return () => {
      removeConfig(config.key);
    };
  }, [appendConfig, config, removeConfig, shouldBlockNavigation]);
};

export default useBlockerNavigation;
