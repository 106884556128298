import { type LoaderFunctionArgs, redirect } from "react-router-dom";

import {
  ORGANIZATIONS_COLLECTION,
  SPECIALIZATION_COLLECTION,
} from "@/firebase/constants";
import { getFirestoreDocs } from "@/firebase/firestore";
import { type Organization } from "@/firebase/types/Organization";
import { type Specialization } from "@/firebase/types/Specialization";
import useFirestoreData from "@/store/useFirestoreData";

const routerGlobalLoader = async ({ request: { url } }: LoaderFunctionArgs) => {
  // ! Remove trailing slash in url
  // ! Fixing the issue of the router not matching the route when end with trailing slash
  const lastPathnameChar = url[url.length - 1];
  if (lastPathnameChar === "/") {
    const pathnameWithoutTrailingSlash = url.slice(0, -1);
    return redirect(pathnameWithoutTrailingSlash);
  }

  const specializations = await getFirestoreDocs<Specialization>(
    SPECIALIZATION_COLLECTION
  );
  const organizations = await getFirestoreDocs<Organization>(
    ORGANIZATIONS_COLLECTION
  );

  useFirestoreData.setState({ specializations, organizations });

  return null;
};

export default routerGlobalLoader;
