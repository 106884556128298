import classNames from "classnames";

import { H3 } from "@/components/Foundations/Typography";

import styles from "./CardHeader.module.scss";

export interface CardHeaderProps {
  title?: string;
  icon?: JSX.Element;
  children?: JSX.Element | JSX.Element[] | null;
  paddingSize?: "small" | "regular";
  mobileActionsTop?: boolean;
  variant?: "light" | "dark" | "transparent";
}

const CardHeader = ({
  title,
  icon,
  children,
  paddingSize = "regular",
  mobileActionsTop = false,
  variant = "light",
}: CardHeaderProps) => {
  const cardHeaderStyles = classNames([
    styles.cardHeader,
    styles[paddingSize],
    styles[variant],
    {
      [styles.withMobileActionsTop]: mobileActionsTop,
    },
  ]);

  const cardHeaderTitleContainerStyles = classNames([
    styles.cardHeaderTitleContainer,
  ]);

  const cardActionsStyle = classNames([
    styles.actions,
    {
      "w-full": !title,
    },
  ]);

  return (
    <div className={cardHeaderStyles}>
      {!!title || icon ? (
        <div className={cardHeaderTitleContainerStyles}>
          {icon}
          {title && <H3 value={title} />}
        </div>
      ) : null}
      {children && <div className={cardActionsStyle}>{children}</div>}
    </div>
  );
};

export default CardHeader;
