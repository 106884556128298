import { type TypographyValue, type TypographyVariantObject } from "..";

type TypographyCaptionProps = JSX.IntrinsicElements["span"] &
  TypographyVariantObject<"medium" | "bold"> &
  TypographyValue;

const TypographyCaption = ({
  value = "",
  variant = "medium",
  className = "",
  testId,
  ...props
}: TypographyCaptionProps) => (
  <span
    className={`text-caption font-${variant} ${className}`}
    data-test-id={testId}
    {...props}
  >
    {value}
  </span>
);

export default TypographyCaption;
