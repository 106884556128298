import { useEffect, useMemo } from "react";
import { type FieldValues, FormProvider, useForm } from "react-hook-form";

import FormDevTool from "@/components/_dev/FormDevTool";
import Spinner from "@/components/Atoms/Spinner";
import { type EntityFormProps } from "@/entity/types";
import useBlockerNavigation from "@/hooks/useBlockerNavigation";
import { DEFAULT_EMPTY_STRING } from "@/utils/utilityConstants";

const createEntityFormComponent = <TForm extends FieldValues>() => {
  const FormProviderElement = ({
    children,
    defaultValues,
    formBlockerConfigs: _formBlockerConfigs,
    devTool = true,
  }: EntityFormProps<TForm>) => {
    const { formState, ...formProps } = useForm<TForm>({
      criteriaMode: "all",
      mode: "all",
      defaultValues: async () => defaultValues,
      reValidateMode: "onBlur",
    });

    const shouldBlockNavigation = useMemo(
      () =>
        !!_formBlockerConfigs && formState.isDirty && !formState.isSubmitting,
      [_formBlockerConfigs, formState.isDirty, formState.isSubmitting]
    );

    const formBlockerConfigs = useMemo(
      () => _formBlockerConfigs ?? { key: DEFAULT_EMPTY_STRING },
      [_formBlockerConfigs]
    );

    useBlockerNavigation(shouldBlockNavigation, formBlockerConfigs);

    useEffect(() => {
      if (formState.isSubmitSuccessful)
        formProps.reset(formProps.getValues(), { keepValues: true });
    }, [formProps, formState.isSubmitSuccessful]);

    if (formState.isLoading) return <Spinner fullscreen />;

    return (
      <FormProvider formState={formState} {...formProps}>
        {children}
        {devTool && <FormDevTool control={formProps.control} />}
      </FormProvider>
    );
  };
  return FormProviderElement;
};

export default createEntityFormComponent;
