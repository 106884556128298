import classNames from "classnames";

import styles from "./CardFooter.module.scss";

export interface CardFooterProps {
  children?: JSX.Element | JSX.Element[] | null;
  actionsAlignment?: "left" | "center" | "right";
  variant?: "light" | "dark" | "transparent";
}
const CardFooter = ({
  children,
  actionsAlignment = "center",
  variant = "light",
}: CardFooterProps) => {
  const cardFooterStyles = classNames([
    styles.cardFooter,
    styles[actionsAlignment],
    styles[variant],
  ]);

  return <div className={cardFooterStyles}>{children}</div>;
};

export default CardFooter;
