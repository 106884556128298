import useContactUsStore from "@/store/useContactUsStore";

const useContactUsModal = () => {
  const isContactUsModalOpen = useContactUsStore(
    (state) => state.isContactUsModalOpen
  );

  const openContactUsModal = useContactUsStore(
    (state) => state.openContactUsModal
  );

  const closeContactUsModal = useContactUsStore(
    (state) => state.closeContactUsModal
  );

  return { isContactUsModalOpen, openContactUsModal, closeContactUsModal };
};

export default useContactUsModal;
