import { createContext } from "react";

import { type AssistantData } from "@/api/assistant/types";

export interface AssistantDicomDataContextProps {
  caseId: string;
  assistantData: AssistantData;
  isLoadingAssistantData: boolean;
}

const AssistantDicomDataContext = createContext<AssistantDicomDataContextProps>(
  {
    caseId: "",
    assistantData: {
      doctor: {
        firstName: "",
        lastName: "",
      },
      isSubmitted: false,
      sendCaseEnable: false,
    },
    isLoadingAssistantData: true,
  } satisfies AssistantDicomDataContextProps
);

export default AssistantDicomDataContext;
