import "./Toast.override.scss";

import { type FC, useCallback, useEffect, useState } from "react";
import { RiInformationLine } from "react-icons/ri";
import { toast } from "react-toastify";

import Alert, { type AlertProps } from "@/components/Molecules/Alert";

export type ToastProps = Pick<
  AlertProps,
  "variant" | "title" | "description" | "leftIcon" | "testId"
> & { disableCloseButton?: boolean; toastId?: string; timeout?: number };

const Toast: FC<ToastProps> = ({
  variant = "info",
  title = "",
  description = "",
  leftIcon = <RiInformationLine />,
  testId,
  disableCloseButton = false,
  toastId,
  timeout,
}) => {
  const closeToast = useCallback(() => {
    toast.dismiss(toastId);
  }, [toastId]);
  const [progressValue, setProgressValue] = useState(timeout);

  useEffect(() => {
    if (!timeout) return;
    const tick = 10;
    let actualTime = timeout;

    const interval = setInterval(() => {
      setProgressValue(() => {
        actualTime -= tick;
        if (actualTime <= 0) closeToast();
        return (actualTime / timeout) * 100;
      });
    }, tick);

    // Unmount is called when the toast is dismissed
    return () => {
      clearInterval(interval);
      setProgressValue(0);
    };
  }, [closeToast, timeout]);

  return (
    <Alert
      variant={variant}
      title={title}
      description={description}
      size="full"
      onClose={disableCloseButton ? undefined : closeToast}
      progressValue={timeout ? progressValue : undefined}
      leftIcon={leftIcon}
      testId={testId}
    />
  );
};

export default Toast;
