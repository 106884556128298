import { t } from "i18next";

import { getFirestoreDoc } from "@/firebase/firestore";
import { UserErrors } from "@/firebase/types/Errors/UserErrors";
import {
  type FirestoreOrganization,
  type Organization,
  type OrganizationRelation,
} from "@/firebase/types/Organization";
import {
  type Specialization,
  type SpecializationUserRelation,
} from "@/firebase/types/Specialization";
import { type PopulatedUser, type User } from "@/firebase/types/User";
import useFirestoreData from "@/store/useFirestoreData";
import { invokeToastError } from "@/utils/toastify";

import { USERS_COLLECTION } from "./constants";
import { getOrganizationListFromFirestoreData } from "./organizations";
import { getModuleConfig } from "./systemConfig";
import { type Case, type FirestoreCase } from "./types/Case";
import { type UserPrivileges } from "./types/Privileges";
import { type PopulatedRole } from "./types/Role";

export const getModulesAndFeaturesPrivilegesForRole = async (
  role: PopulatedRole
) => {
  const initialPrivileges: UserPrivileges = {
    allowedModules: [],
    allowedFeatures: [],
  };

  try {
    const moduleConfig = await getModuleConfig();
    const modules = moduleConfig.modules;
    const privileges: UserPrivileges = modules.reduce(
      ({ allowedModules, allowedFeatures }, module) => {
        let internalAllowedModules = [...allowedModules];
        let internalAllowedFeatures = [...allowedFeatures];

        const hasAllPrivileges = role.privileges.includes("all");

        if (!hasAllPrivileges && !module.enable)
          return {
            allowedFeatures: internalAllowedFeatures,
            allowedModules: internalAllowedModules,
          };

        if (hasAllPrivileges) {
          internalAllowedModules = [...internalAllowedModules, module.name];
          module.features.forEach((feature) => {
            internalAllowedFeatures = [
              ...internalAllowedFeatures,
              feature.name,
            ];
          });
        }

        const moduleCanBeUnlocked = role.privileges.some((privilege) =>
          module.authorizationPrivileges.includes(privilege)
        );
        if (!moduleCanBeUnlocked)
          return {
            allowedFeatures: internalAllowedFeatures,
            allowedModules: internalAllowedModules,
          };

        internalAllowedModules = [...internalAllowedModules, module.name];
        module.features
          .filter((feature) =>
            feature.authorizationPrivileges.every((auth) =>
              role.privileges.includes(auth)
            )
          )
          .forEach((feature) => {
            internalAllowedFeatures = [
              ...internalAllowedFeatures,
              feature.name,
            ];
          });

        return {
          allowedFeatures: internalAllowedFeatures,
          allowedModules: internalAllowedModules,
        };
      },
      initialPrivileges
    );
    return privileges;
  } catch (error) {
    invokeToastError({
      title: t("generic.danger"),
      description: String(error),
    });
    return initialPrivileges;
  }
};

const mapEntityId = <T extends { id: string }>(
  values: T[] | undefined
): Array<T["id"]> => (values ? values?.map((v) => v.id) : []);

export const populateSpecializationByIds = (
  ids: string[]
): Specialization[] => {
  const specializationList = useFirestoreData.getState().specializations;

  return ids.length > 0
    ? specializationList.filter((specialization) =>
        ids.includes(specialization.id)
      )
    : [];
};

export const populateOrganizationByIds = (ids: string[]): Organization[] => {
  const organizationList = getOrganizationListFromFirestoreData();

  return ids.length > 0
    ? organizationList.filter((organization) => ids.includes(organization.id))
    : [];
};

export const populateUserById = async (id: string): Promise<PopulatedUser> => {
  const user = await getFirestoreDoc<User>(USERS_COLLECTION, id);
  if (!user) throw new Error(UserErrors.NOT_FOUND_USER);

  const specializationIds = mapEntityId<SpecializationUserRelation>(
    user?.specializations
  );
  const organizationIds = mapEntityId<OrganizationRelation>(
    user?.organizations
  );

  const specializations = populateSpecializationByIds(specializationIds);

  const organizations = populateOrganizationByIds(organizationIds);

  return {
    ...user,
    specializations,
    organizations,
  };
};

export const serializeFirestoreCaseIntoCase = (
  fireStoreCase: FirestoreCase | undefined
) => {
  if (!fireStoreCase) return;

  const baseCase: Case = {
    ...fireStoreCase,
    patient: {
      firstName: fireStoreCase.patient?.firstName ?? "",
      lastName: fireStoreCase.patient?.lastName ?? "",
      sex: fireStoreCase.patient?.sex,
      birthDate: fireStoreCase.patient?.birthDate?.toDate(),
    },
    detail: {
      ...fireStoreCase.detail,
      operationDate: fireStoreCase.detail.operationDate?.toDate(),
    },
  };

  return baseCase;
};

export const serializeFirestoreOrganizationIntoOrganization = (
  fireStoreOrganization: FirestoreOrganization | undefined
) => {
  if (!fireStoreOrganization) throw new Error("No firestore organization");

  const baseOrganization: Organization = {
    ...fireStoreOrganization,
    license: fireStoreOrganization.license
      ? {
          ...fireStoreOrganization.license,
          issuedDate: fireStoreOrganization.license.issuedDate.toDate(),
          expireDate: fireStoreOrganization.license.expireDate.toDate(),
        }
      : null,
  };

  return baseOrganization;
};
