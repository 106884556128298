import {
  collection,
  getCountFromServer,
  query,
  type QuerySnapshot,
  where,
} from "firebase/firestore";

import {
  sendNotificationMessages,
  type sendNotificationMessagesProps,
} from "@/api/sendNotificationMessages";
import { type Notification } from "@/firebase/types/Notification";
import { concatenateStrings } from "@/utils/utilityFunction";

import { NOTIFICATIONS_COLLECTION, USERS_COLLECTION } from "./constants";
import { db } from "./firebase.config";
import { getFirestoreDocsWithCursors } from "./firestore";
import { type ReCaptchaError } from "./types/Errors/ReCaptchaErrors";
import { type User } from "./types/User";

export const getNotifications = async (
  userId: User["id"],
  cursor: QuerySnapshot | null | undefined
) =>
  await getFirestoreDocsWithCursors<Notification>(
    concatenateStrings([USERS_COLLECTION, userId, NOTIFICATIONS_COLLECTION], {
      separator: "/",
    }),
    {
      limitAmount: 20,
      startAtCursor: cursor,
      orderByData: {
        fieldPath: "createdAt",
        direction: "desc",
      },
    }
  );

export const getUnreadNotificationsCount = async (userId: User["id"]) => {
  const collectionRef = collection(
    db,
    concatenateStrings([USERS_COLLECTION, userId, NOTIFICATIONS_COLLECTION], {
      separator: "/",
    })
  );
  const q = query(collectionRef, where("isRead", "==", false));
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
};

export const sendNotification = async ({
  body,
  subject,
  recaptcha,
}: sendNotificationMessagesProps) => {
  try {
    await sendNotificationMessages({ body, subject, recaptcha });
  } catch (error) {
    throw error as ReCaptchaError;
  }
};
