import { redirect } from "react-router-dom";

import { isUserLoggedIn } from "@/firebase/auth";
import Pages from "@/pages/router-pages";
import { applicationQueryClient } from "@/react-query";

const guestLoader = async () => {
  const logged = await isUserLoggedIn();
  /**
   * Remove Cache of React Query when logged out; please don't touch that.
   */
  applicationQueryClient.removeQueries();
  if (logged) {
    return redirect(Pages.HOME);
  }
  return null;
};

export default guestLoader;
