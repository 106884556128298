import classNames from "classnames";
import { type ReactElement, type ReactNode } from "react";

import CardFooter, {
  type CardFooterProps,
} from "@/components/Molecules/CardFooter";
import CardHeader, {
  type CardHeaderProps,
} from "@/components/Molecules/CardHeader";

import styles from "./Card.module.scss";

export interface CardProps {
  Header?: ReactElement<CardHeaderProps> | null;
  Footer?: ReactElement<CardFooterProps> | null;
  withShadow?: boolean;
  size?: "small" | "medium" | "large";
  children: JSX.Element | ReactNode;
  testId?: string;
  withModal?: boolean;
  fullHeight?: boolean;
  variant?: "light" | "dark" | "transparent";
}

const Card = ({
  Header,
  Footer,
  withShadow = false,
  size = "large",
  testId,
  children,
  withModal = false,
  fullHeight = false,
  variant = "light",
}: CardProps) => {
  const cardStyles = classNames([styles.card, styles[size]], {
    [styles.withShadow]: withShadow,
    [styles.withHeader]: !!Header,
    [styles.withFooter]: !!Footer,
    [styles.withModal]: withModal,
    [styles[variant]]: variant,
    "h-full": fullHeight,
  });

  return (
    <div data-test-id={testId} className={cardStyles}>
      {Header}
      <>{children}</>
      {Footer}
    </div>
  );
};

Card.Header = CardHeader;
Card.Footer = CardFooter;

export default Card;
