import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { type ButtonProps } from "@/components/Atoms/Button";
import { type TextButtonProps } from "@/components/Atoms/TextButton";
import { useContactUsForm } from "@/components/ContactUsModal/form";
import Modal from "@/components/Organisms/Modal/index";
import useIsDemoDoctor from "@/firebase/hooks/useIsDemoDoctor";
import { sendNotification } from "@/firebase/notifications";
import { type ReCaptchaError } from "@/firebase/types/Errors/ReCaptchaErrors";
import useMutation from "@/hooks/useMutation";
import { getErrorByCode } from "@/utils/errorsBycode";
import { instanceOf } from "@/utils/typeCheck";

import ContactUsForm from "./ContactUsForm";
import useContactUsModal from "./hooks/useContactUsModal";

const ContactUsModal = () => {
  const { t } = useTranslation();

  const { isContactUsModalOpen, closeContactUsModal } = useContactUsModal();

  const isDemoDoctor = useIsDemoDoctor();
  const { handleSubmit, setError, reset } = useContactUsForm();

  const { mutate: sendNotificationMessagesMutation, isPending } = useMutation(
    sendNotification,
    {
      successDescription: t("contactUs.form.toast.success"),
      successTestId: "contactUs-form-success",
      errorDescription: (error: ReCaptchaError | Error) => {
        if (instanceOf<ReCaptchaError>(error, "code"))
          return t([
            `contactUs.form.validation.${error.code}`,
            "contactUs.form.toast.error",
          ]);

        return t("contactUs.form.toast.error");
      },
      errorTestId: "contactUs-form-error",
    }
  );

  const localSubmit: Parameters<typeof handleSubmit>[0] = useCallback(
    async (values) => {
      let body = `${values.message} \n\n Privacy: ${
        values.privacyAccepted ? t(`generic.accepted`) : t(`generic.rejected`)
      }`;

      if (isDemoDoctor)
        body = `${t(`contactUs.sendBy.demoDoctor`)}:\n\n${body}`;

      sendNotificationMessagesMutation(
        {
          body,
          subject: `Message from ${values.name} ${values.surname} ${values.email}`,
          recaptcha: values.recaptcha,
        },
        {
          onSuccess: () => {
            reset();
            closeContactUsModal();
          },
          onError: (error) => {
            if (instanceOf<ReCaptchaError>(error, "code")) {
              const errorCode = error.code;
              if (errorCode) {
                switch (errorCode) {
                  case "recaptcha/timeout-or-duplicate":
                    setError(
                      "recaptcha",
                      getErrorByCode(errorCode, "contactUs.form.validation")
                    );
                    break;
                }
              }
            }
          },
        }
      );
    },
    [
      closeContactUsModal,
      isDemoDoctor,
      reset,
      sendNotificationMessagesMutation,
      setError,
      t,
    ]
  );

  const contactUsModalPrimaryActions = useMemo(
    () =>
      [
        {
          label: t("contactUs.form.send.button"),
          variant: "primary",
          buttonProps: {
            type: "button",
            onClick: handleSubmit(localSubmit),
          },
          isLoading: isPending,
        },
      ] as ButtonProps[],
    [handleSubmit, isPending, localSubmit, t]
  );

  const contactUsModalSecondaryActions = useMemo(
    () =>
      [
        {
          label: t("generic.cancel"),
          buttonProps: {
            type: "button",
            onClick: closeContactUsModal,
          },
        },
      ] as TextButtonProps[],
    [closeContactUsModal, t]
  );

  useEffect(() => {
    if (isContactUsModalOpen) {
      reset();
    }
  }, [isContactUsModalOpen, reset]);

  return (
    <Modal
      testId="contactUs-modal"
      isOpen
      Header={{
        onClose: closeContactUsModal,
        title: t("login.hero.subtitle.link2"),
      }}
      Footer={{
        primaryActionsButtonsProps: contactUsModalPrimaryActions,
        secondaryActionsTextButtonProps: contactUsModalSecondaryActions,
      }}
    >
      <ContactUsForm />
    </Modal>
  );
};

export default ContactUsModal;
