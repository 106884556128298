import {
  TypographyBodySmall,
  TypographyButton,
} from "@/components/Foundations/Typography";

import { type ButtonProps } from "../Button";

const BUTTON_SIZE_VARIANT_MAP = {
  "extra-small": TypographyBodySmall,
  small: TypographyButton,
  medium: TypographyButton,
  large: TypographyButton,
} as const;

export const getButtonSizeVariantComponent = (variant: ButtonProps["size"]) =>
  BUTTON_SIZE_VARIANT_MAP[variant ?? "medium"];
