import dayjs from "dayjs";
import countries from "i18n-iso-countries";
import i18nIsoCountriesDe from "i18n-iso-countries/langs/de.json";
import i18nIsoCountriesEn from "i18n-iso-countries/langs/en.json";
import i18nIsoCountriesEs from "i18n-iso-countries/langs/es.json";
import i18nIsoCountriesFr from "i18n-iso-countries/langs/fr.json";
import i18nIsoCountriesIt from "i18n-iso-countries/langs/it.json";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import {
  DEFAULT_LANGUAGE_FALLBACK,
  I18N_NAMESPACES,
} from "./utils/utilityConstants";
import { Locale, validLocaleOrFallback } from "./utils/valueFormatters";

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const convertDetectedLanguage = (lng: string) => {
  // * If the language is "en-GB" || "en-US
  // * Return the language as it is
  if (
    lng.toLowerCase() === Locale.enGB.toLowerCase() ||
    lng.toLowerCase() === Locale.enUS.toLowerCase()
  )
    return lng;

  // * Split the language by "-" and get the first segment
  const lngFirstSegment = lng.split("-")[0];

  // * If the first segment is "en"
  // * Return the language as "en-US" because we have already covered the case of "en-GB" || "en-US" above
  if (lngFirstSegment === "en") return Locale.enUS;

  // * Format the language as "it-IT" || "de-DE" || "fr-FR" || "es-ES"
  const lng4CharCode = `${lngFirstSegment.toLowerCase()}-${lngFirstSegment.toUpperCase()}`;
  return validLocaleOrFallback(lng4CharCode);
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: {
      order: [
        // * First look for local storage && session storage if the user has already selected a language
        "localStorage",
        "sessionStorage",
        // * Then look for navigator for the browser language
        "navigator",
        // * Finally look for the other default options
        "querystring",
        "cookie",
        "htmlTag",
      ],
      convertDetectedLanguage,
    },
    load: "currentOnly",
    returnNull: false,
    fallbackLng: DEFAULT_LANGUAGE_FALLBACK,
    debug: process.env.NODE_ENV === "development",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

i18n.loadNamespaces([I18N_NAMESPACES.SPECIALIZATIONS, I18N_NAMESPACES.ERRORS]);

i18n.on("languageChanged", (lng) => {
  const converted = convertDetectedLanguage(lng);
  if (lng !== converted) i18n.changeLanguage(converted);
  dayjs.locale(converted);
});
// Sync with "Locale" from "src/utils/valueFormatters.ts"
countries.registerLocale(i18nIsoCountriesEn);
countries.registerLocale(i18nIsoCountriesIt);
countries.registerLocale(i18nIsoCountriesDe);
countries.registerLocale(i18nIsoCountriesFr);
countries.registerLocale(i18nIsoCountriesEs);

export default i18n;
