import { type InsertUser } from "@/firebase/types/User";

import { callApi } from "./apiClient";
import { USER_SIGNUP_API } from "./constants";

export const userSignup = async (
  newUser: InsertUser,
  password: string,
  recaptcha: string
) =>
  await callApi<string>({
    method: "POST",
    url: `/${USER_SIGNUP_API}`,
    data: {
      ...newUser,
      password,
      recaptcha,
    },
  });
