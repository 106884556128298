import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import Spinner from "@/components/Atoms/Spinner";
import EntityRoute from "@/entity/route/EntityRoute";
import { assistantDicomLoader } from "@/pages/Root/AssistantDicom";
import authorizedLoader from "@/pages/Root/Authorized/authorizedLoader";
import guestLoader from "@/pages/Root/Guest/guestLoader";
import routerGlobalLoader from "@/pages/routerGlobalLoader";

import signupLoader from "./Root/Guest/SignUp/signupLoader";
import rootLoader from "./Root/rootLoader";
import Pages from "./router-pages";

const ErrorPage = React.lazy(
  async () => await import("@/components/_placeholders/Errors/ErrorPage")
);

const EmailActionHandler = React.lazy(
  async () => await import("@/pages/Root/Guest/EmailActionHandler")
);

// Layouts
const RouterGlobal = React.lazy(
  async () => await import("@/pages/RouterGlobal")
);
const AuthorizedLayoutPage = React.lazy(
  async () => await import("@/pages/Root/Authorized/AuthorizedLayoutPage")
);
const AuthorizedLayoutError = React.lazy(
  async () => await import("@/pages/Root/Authorized/AuthorizedLayoutError")
);
const GuestLayout = React.lazy(async () => await import("@/pages/Root/Guest"));

const AssistantDicom = React.lazy(
  async () => await import("@/pages/Root/AssistantDicom")
);

// Guest Pages
const Login = React.lazy(async () => await import("@/pages/Root/Guest/Login"));
const SignUp = React.lazy(
  async () => await import("@/pages/Root/Guest/SignUp")
);

const ForgotPassword = React.lazy(
  async () => await import("@/pages/Root/Guest/ForgotPassword")
);

// Authorized Pages
const Home = React.lazy(
  async () => await import("@/pages/Root/Authorized/Home")
);

// Case Pages
const Case = React.lazy(
  async () => await import("@/pages/Root/Authorized/Case/Case")
);
const CaseAdd = React.lazy(
  async () => await import("@/pages/Root/Authorized/Case/CaseAdd")
);

const CaseEdit = React.lazy(
  async () => await import("@/pages/Root/Authorized/Case/CaseEdit")
);

const CaseEditStepOne = React.lazy(
  async () =>
    await import("@/pages/Root/Authorized/Case/CaseEdit/CaseEditStepOne")
);

const CaseEditStepTwo = React.lazy(
  async () =>
    await import("@/pages/Root/Authorized/Case/CaseEdit/CaseEditStepTwo")
);

const CaseEditStepThree = React.lazy(
  async () =>
    await import("@/pages/Root/Authorized/Case/CaseEdit/CaseEditStepThree")
);

const CaseEditStepFour = React.lazy(
  async () =>
    await import("@/pages/Root/Authorized/Case/CaseEdit/CaseEditStepFour")
);

const CaseList = React.lazy(
  async () => await import("@/pages/Root/Authorized/Case/CaseList")
);

const CaseDemoList = React.lazy(
  async () => await import("@/pages/Root/Authorized/Case/CaseDemoList")
);

// User Pages
const User = React.lazy(
  async () => await import("@/pages/Root/Authorized/User")
);

const UserList = React.lazy(
  async () => await import("@/pages/Root/Authorized/User/UserList")
);

const UserAdd = React.lazy(
  async () => await import("@/pages/Root/Authorized/User/UserAdd")
);

const UserEdit = React.lazy(
  async () => await import("@/pages/Root/Authorized/User/UserEdit")
);

// Order Pages
const Order = React.lazy(
  async () => await import("@/pages/Root/Authorized/Order")
);

const OrderList = React.lazy(
  async () => await import("@/pages/Root/Authorized/Order/OrderList")
);

const OrderAdd = React.lazy(
  async () => await import("@/pages/Root/Authorized/Order/OrderAdd")
);

const OrderEdit = React.lazy(
  async () => await import("@/pages/Root/Authorized/Order/OrderEdit")
);
// Specialization
const Specialization = React.lazy(
  async () => await import("@/pages/Root/Authorized/Specialization")
);
const SpecializationAdd = React.lazy(
  async () =>
    await import("@/pages/Root/Authorized/Specialization/SpecializationAdd")
);
const SpecializationEdit = React.lazy(
  async () =>
    await import("@/pages/Root/Authorized/Specialization/SpecializationEdit")
);
const SpecializationList = React.lazy(
  async () =>
    await import("@/pages/Root/Authorized/Specialization/SpecializationList")
);

// Organization Pages
const Organization = React.lazy(
  async () => await import("@/pages/Root/Authorized/Organization")
);

const OrganizationList = React.lazy(
  async () =>
    await import("@/pages/Root/Authorized/Organization/OrganizationList")
);

const OrganizationAdd = React.lazy(
  async () =>
    await import("@/pages/Root/Authorized/Organization/OrganizationAdd")
);

const OrganizationEdit = React.lazy(
  async () =>
    await import("@/pages/Root/Authorized/Organization/OrganizationEdit")
);

const Distributor = React.lazy(
  async () => await import("@/pages/Root/Authorized/Distributor")
);

const DistributorAdd = React.lazy(
  async () => await import("@/pages/Root/Authorized/Distributor/DistributorAdd")
);

const DistributorEdit = React.lazy(
  async () =>
    await import("@/pages/Root/Authorized/Distributor/DistributorEdit")
);

const DistributorList = React.lazy(
  async () =>
    await import("@/pages/Root/Authorized/Distributor/DistributorList")
);

// Delegate Pages
const Delegate = React.lazy(
  async () => await import("@/pages/Root/Authorized/Delegate")
);

const DelegateList = React.lazy(
  async () => await import("@/pages/Root/Authorized/Delegate/DelegateList")
);

// PasswordEdit
const PasswordEdit = React.lazy(
  async () => await import("@/pages/Root/Authorized/PasswordEdit")
);

// PersonalData
const PersonalData = React.lazy(
  async () => await import("@/pages/Root/Authorized/PersonalData")
);

// PersonalData
const GuideLines = React.lazy(
  async () => await import("@/pages/Root/Authorized/GuideLines")
);

const GuideLinesAddFile = React.lazy(
  async () =>
    await import(
      "@/pages/Root/Authorized/GuideLines/GuideLinesUploadFile/GuideLinesUploadFile"
    )
);

const GuideLinesAddVideo = React.lazy(
  async () =>
    await import("@/pages/Root/Authorized/GuideLines/GuideLinesUploadVideo")
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      loader={routerGlobalLoader}
      element={
        <React.Suspense fallback={<Spinner fullscreen />}>
          <RouterGlobal />
        </React.Suspense>
      }
      errorElement={
        <React.Suspense fallback={<Spinner fullscreen />}>
          <ErrorPage />
        </React.Suspense>
      }
    >
      <Route path={Pages.ROOT} loader={rootLoader} />

      <Route
        path={Pages.ASSISTANT_DICOM}
        element={<AssistantDicom />}
        loader={assistantDicomLoader}
      />
      {/* Route GuestLayout acts as Guest Pages Layout */}
      <Route element={<GuestLayout />} loader={guestLoader}>
        <Route path={Pages.LOGIN} element={<Login />} />
        <Route
          path={Pages.SIGN_UP}
          element={<SignUp />}
          loader={signupLoader}
        />
        <Route path={Pages.SIGN_UP_DEMO_USER} element={<SignUp demoUser />} />
        <Route path={Pages.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route
          path={Pages.EMAIL_ACTION_HANDLER}
          element={<EmailActionHandler />}
        />
      </Route>

      {/* Route AuthorizedLayout acts as Authorized Pages Layout */}
      <Route
        element={<AuthorizedLayoutPage />}
        loader={authorizedLoader}
        errorElement={
          <React.Suspense fallback={<Spinner fullscreen />}>
            <AuthorizedLayoutError />
          </React.Suspense>
        }
      >
        <Route path={Pages.HOME} element={<Home />} />
        <Route element={<Case />}>
          <Route path={Pages.CASE_EDIT} element={<CaseEdit />}>
            <Route
              path={Pages.CASE_EDIT_STEP_ONE}
              element={<CaseEditStepOne />}
            />
            <Route
              path={Pages.CASE_EDIT_STEP_TWO}
              element={<CaseEditStepTwo />}
            />
            <Route
              path={Pages.CASE_EDIT_STEP_THREE}
              element={<CaseEditStepThree />}
            />
            <Route
              path={Pages.CASE_EDIT_STEP_FOUR}
              element={<CaseEditStepFour />}
            />
          </Route>
          <Route path={Pages.CASE_ADD} element={<CaseAdd />} />
          <Route path={Pages.CASE_LIST} element={<CaseList />} />
          <Route path={Pages.CASE_DEMO_LIST} element={<CaseDemoList />} />
        </Route>
        {EntityRoute({
          basePath: Pages.ORDERS,
          BaseComponent: <Order />,
          AddComponent: <OrderAdd />,
          EditComponent: <OrderEdit />,
          ListComponent: <OrderList />,
        })}
        <Route element={<Delegate />}>
          <Route path={Pages.DELEGATE_LIST} element={<DelegateList />} />
        </Route>
        <Route element={<User />}>
          <Route path={Pages.USERS_LIST} element={<UserList />} />
          <Route path={Pages.USERS_ADD} element={<UserAdd />} />
          <Route path={Pages.USERS_EDIT} element={<UserEdit />} />
        </Route>
        {EntityRoute({
          basePath: Pages.SPECIALIZATIONS,
          BaseComponent: <Specialization />,
          AddComponent: <SpecializationAdd />,
          EditComponent: <SpecializationEdit />,
          ListComponent: <SpecializationList />,
        })}

        {EntityRoute({
          basePath: Pages.DISTRIBUTORS,
          BaseComponent: <Distributor />,
          AddComponent: <DistributorAdd />,
          EditComponent: <DistributorEdit />,
          ListComponent: <DistributorList />,
        })}
        {EntityRoute({
          basePath: Pages.ORGANIZATIONS,
          BaseComponent: <Organization />,
          AddComponent: <OrganizationAdd />,
          EditComponent: <OrganizationEdit />,
          ListComponent: <OrganizationList />,
        })}
        <Route path={Pages.GUIDELINES} element={<GuideLines />} />
        <Route
          path={Pages.GUIDELINES_UPLOAD_FILE}
          element={<GuideLinesAddFile />}
        />
        <Route
          path={Pages.GUIDELINES_UPLOAD_VIDEO}
          element={<GuideLinesAddVideo />}
        />
        <Route path={Pages.PASSWORD_EDIT} element={<PasswordEdit />} />
        <Route path={Pages.PERSONAL_DATA} element={<PersonalData />} />
      </Route>
    </Route>
  )
);

export default router;
