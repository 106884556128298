import React, { type FC } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import InputText from "@/components/Atoms/Input";
import { type ContactUsFormControllerProps } from "@/components/ContactUsModal/types";

const ContactUsSurnameController: FC<ContactUsFormControllerProps> = ({
  control,
  errors,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="surname"
      render={({
        field: { disabled, onChange, onBlur, value, ...inputProps },
      }) => (
        <InputText
          title={t("registration.form.personalInfo.surname")}
          value={value}
          onChange={onChange}
          inputProps={{
            required: true,
            placeholder: t(`generic.placeholder.lastName`),
            onBlur: () => onChange(value.trim()),
            ...inputProps,
          }}
          inputTestId="contactUs-form-surname"
          validationData={{
            errors: errors.surname?.types,
            testId: "contactUs-form-surname-validation",
          }}
        />
      )}
      rules={{
        required: t("generic.field.validation.required"),
      }}
    />
  );
};

export default ContactUsSurnameController;
