import {
  type FieldValues,
  useFormContext,
  useFormState,
} from "react-hook-form";

import createEntityFormComponent from "@/entity/form/createEntityFormComponent";

const createEntityForm = <TEntityFormValues extends FieldValues>() => {
  const EntityFormComponent = createEntityFormComponent<TEntityFormValues>();
  const useEntityForm = useFormContext<TEntityFormValues>;
  const useEntityFormState = useFormState<TEntityFormValues>;

  return {
    EntityFormComponent,
    useEntityForm,
    useEntityFormState,
  };
};

export default createEntityForm;
