import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  type DocumentData,
  getCountFromServer,
  getDoc,
  getDocs,
  onSnapshot,
  type QuerySnapshot,
  setDoc,
  updateDoc,
  type WithFieldValue,
  writeBatch,
} from "firebase/firestore";

import { type ObjectWithIdKeysString } from "@/firebase/types/utils";

import { SYSTEM_CONFIGURATION_COLLECTION } from "../constants";
import { db } from "../firebase.config";
import {
  type GetFirestoreDocsOptions,
  type GetFirestoreDocsWithCursorsReturn,
} from "./types";
import castDocIntoType from "./utils/castDocIntoType";
import prepareQuery from "./utils/prepareQuery";

/**
 * @example
 * const user = await getFirestoreDoc<{ email: string }>(
 *  "users",
 *  "tizio@example.com"
 * );
 */
export const getFirestoreDoc = async <T extends { id: string }>(
  collectionName: string,
  id: string
) => {
  const docRef = doc(db, collectionName, id.trim());
  const docSnapshot = await getDoc(docRef);
  if (docSnapshot.exists()) {
    return castDocIntoType<T>(docSnapshot);
  } else {
    throw new Error(`Not Found Entity ${collectionName}, id: ${id}`);
  }
};

/**
 * @example
 *  const usersWithSpecificEmailAndName = await getFirestoreDocs<{ user: User }>(
 *    "users",
 *    ["email", "==", "tizio@example.com"],
 *    ["name", "==", "tizio"]
 *  );
 */
export const getFirestoreDocs = async <T extends { id: string }>(
  collectionName: string,
  {
    wheres,
    limitAmount,
    orderByData,
  }: GetFirestoreDocsOptions<ObjectWithIdKeysString<T>> = {}
) => {
  const q = prepareQuery({ collectionName, wheres, limitAmount, orderByData });

  const docsSnapshot = await getDocs(q);
  return docsSnapshot.docs.map((docRef) => {
    return castDocIntoType<T>(docRef);
  });
};

export const getFirestoreDocsWithCursors = async <T extends { id: string }>(
  collectionName: string,
  {
    wheres,
    limitAmount = 10,
    orderByData,
    startAtCursor,
  }: GetFirestoreDocsOptions<ObjectWithIdKeysString<T>> = {}
): Promise<GetFirestoreDocsWithCursorsReturn<T>> => {
  const q = prepareQuery({
    collectionName,
    wheres,
    limitAmount,
    orderByData,
    startAtCursor,
  });
  const docsSnapshot = await getDocs(q);
  const countQuery = prepareQuery({
    collectionName,
    wheres,
  });
  const countFromServer = await getCountFromServer(countQuery);
  return {
    data: docsSnapshot.docs.map((docRef) => {
      return castDocIntoType<T>(docRef);
    }),
    next: docsSnapshot.docs[
      docsSnapshot.docs.length - 1
    ] as unknown as QuerySnapshot<DocumentData>,
    count: countFromServer.data().count,
  };
};

export const createFirestoreDoc = async <
  T extends WithFieldValue<DocumentData> = any
>(
  collectionName: string,
  data: T
) => {
  const docRef = await addDoc(collection(db, collectionName), data);
  return docRef.id;
};

export const setFirestoreDoc = async <
  T extends WithFieldValue<DocumentData> = any
>(
  collectionName: string,
  id: string,
  data: T
) => {
  const docRef = doc(db, collectionName, id);
  await setDoc(docRef, data);
};

export const updateFirestoreDoc = async <
  T extends WithFieldValue<DocumentData> = any
>(
  collectionName: string,
  id: string,
  data: T
) => {
  const docRef = doc(db, collectionName, id);
  await updateDoc(docRef, data);
};

export const deleteFirestoreDoc = async (
  collectionName: string,
  id: string
) => {
  const docRef = doc(db, collectionName, id);
  await deleteDoc(docRef);
};

export const deleteFirebaseDocs = async (
  collectionName: string,
  ids: string[]
) => {
  const batch = writeBatch(db);
  ids.forEach((id) => {
    const docRef = doc(db, collectionName, id);
    batch.delete(docRef);
  });
  await batch.commit();
};

export const firestoreDocRefSnapshot = <T extends { id: string }>(
  collectionName: string,
  id: string,
  handleFunction: (data: T) => void
) => {
  const docRef = doc(db, collectionName, id);
  return onSnapshot(docRef, (docSnap) => {
    const doc = castDocIntoType<T>(docSnap);
    if (!docSnap.exists()) return;
    handleFunction(doc);
  });
};

export const firestoreCollectionRefSnapshot = <T extends { id: string }>(
  collectionName: string,
  handleFunction: (data: T[]) => void,
  {
    wheres,
    limitAmount,
    orderByData,
    startAtCursor,
  }: GetFirestoreDocsOptions<ObjectWithIdKeysString<T>> = {}
) => {
  const queryRef = prepareQuery({
    collectionName,
    startAtCursor,
    wheres,
    limitAmount,
    orderByData,
  });

  return onSnapshot(queryRef, (collectionSnap) => {
    const docs = collectionSnap.docs.map((docRef) => {
      return castDocIntoType<T>(docRef);
    });
    handleFunction(docs);
  });
};

export const getFirestoreSystemConfig = async <T extends { id: string }>(
  key: string
) => await getFirestoreDoc<T>(SYSTEM_CONFIGURATION_COLLECTION, key);
