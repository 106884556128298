import { callApi } from "./apiClient";
import { EXTEND_PASSWORD_EXPIRATION_AFTER_RESET_PASSWORD_API } from "./constants";

export const extendPasswordExpirationAfterResetPassword = async ({
  userId,
  oobCode,
}: {
  userId: string;
  oobCode: string;
}) =>
  await callApi({
    method: "POST",
    url: `/${EXTEND_PASSWORD_EXPIRATION_AFTER_RESET_PASSWORD_API}`,
    data: {
      userId,
      oobCode,
    },
  });
