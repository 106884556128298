/* eslint-disable react/display-name */
import classNames from "classnames";
import { forwardRef } from "react";

import { DEFAULT_EMPTY_STRING } from "@/utils/utilityConstants";

import InputContainer, { type InputContainerProps } from "../InputContainer";
import styles from "./Input.module.scss";

export type InputTextProps = {
  value?: string | null;
  isDisabled?: boolean;
  isInvalid?: boolean;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  /**
   * Props del input
   */
  inputProps?: JSX.IntrinsicElements["input"];
  onChange?: JSX.IntrinsicElements["input"]["onChange"];
  inputTestId?: string;
} & InputContainerProps;

const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  (
    {
      title = DEFAULT_EMPTY_STRING,
      titleProps,
      titleTestId,
      helpMessage = DEFAULT_EMPTY_STRING,
      helpMessageTestId,
      validationData: { errors, testId: errorMessageTestId } = {},
      isInvalid = false,
      value = DEFAULT_EMPTY_STRING,
      onChange,
      inputProps: {
        required,
        onChange: onChangeInputProp,
        className,
        ...inputProps
      } = {},
      isDisabled: isDisabledProp = false,
      leftIcon,
      rightIcon,
      inputTestId,
    },
    ref
  ) => {
    const isDisabled = inputProps?.disabled ?? isDisabledProp;

    const isInvalidInternal = errors
      ? !Object.values(errors).every((err) => err === true)
      : isInvalid;

    const inputWrapperStyles = classNames([styles.inputWrapper], {
      [styles.disabledWrapper]: isDisabled,
      [styles.invalidWrapper]: isInvalidInternal,
    });

    const inputStyles = classNames([
      styles.input,
      {
        [styles.inputDate]: inputProps.type === "date",
      },
    ]);

    return (
      <InputContainer
        validationData={{
          errors,
          testId: errorMessageTestId,
        }}
        isDisabled={isDisabled}
        title={title}
        isRequired={required}
        titleProps={titleProps}
        titleTestId={titleTestId}
        isInvalid={isInvalidInternal}
        helpMessage={helpMessage}
        helpMessageTestId={helpMessageTestId}
      >
        <div className={inputWrapperStyles}>
          {leftIcon}
          <input
            ref={ref}
            value={value ?? DEFAULT_EMPTY_STRING}
            onChange={onChange}
            disabled={isDisabled}
            className={inputStyles}
            data-test-id={inputTestId}
            required={required}
            {...inputProps}
          />
          {rightIcon}
        </div>
      </InputContainer>
    );
  }
);

export default InputText;
