import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

import {
  type FileSnapshotDocumentTypeMap,
  type FileSnapshotFileMap,
  type FileSnapshotsMap,
  type useFileProgressUploadStoreType,
} from "./types";

const useFileProgressUploadStore = create(
  subscribeWithSelector<useFileProgressUploadStoreType>((set, get) => ({
    fileSnapshotsMap: new Map(),
    setFileProgressSnapshot: ({
      uploaderRefId,
      documentType,
      fileRefName,
      snapshot,
    }) => {
      const newFileSnapshotsMap =
        get()?.fileSnapshotsMap ?? (new Map() as FileSnapshotsMap);

      const newFileSnapshotMapUploaderRefId =
        newFileSnapshotsMap.get(uploaderRefId) ??
        (new Map() as FileSnapshotDocumentTypeMap);

      const newFileSnapshotMapDocumentType =
        newFileSnapshotMapUploaderRefId.get(documentType) ??
        (new Map() as FileSnapshotFileMap);

      newFileSnapshotMapDocumentType.set(fileRefName, snapshot);
      newFileSnapshotMapUploaderRefId.set(
        documentType,
        newFileSnapshotMapDocumentType
      );
      newFileSnapshotsMap.set(uploaderRefId, newFileSnapshotMapUploaderRefId);

      set({ fileSnapshotsMap: newFileSnapshotsMap });
    },
    removeFileSnapshotDocumentType: ({ uploaderRefId, documentType }) => {
      const newFileSnapshotMap =
        get()?.fileSnapshotsMap ?? (new Map() as FileSnapshotsMap);

      const newFileSnapshotMapRefId =
        newFileSnapshotMap.get(uploaderRefId) ??
        (new Map() as FileSnapshotDocumentTypeMap);

      newFileSnapshotMapRefId.delete(documentType);

      newFileSnapshotMap.set(uploaderRefId, newFileSnapshotMapRefId);

      set({ fileSnapshotsMap: newFileSnapshotMap });
    },
  }))
);

export default useFileProgressUploadStore;
