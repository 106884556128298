import { type FC } from "react";

import {
  useContactUsForm,
  useContactUsFormState,
} from "@/components/ContactUsModal/form";
import FormPrivacyController from "@/entity/form/Controllers/FormPrivacyController";
import FormReCaptchaController from "@/entity/form/Controllers/FormReCaptchaController";

import styles from "./ContactUsForm.module.scss";
import ContactUsEmailController from "./Controllers/ContactUsEmailController";
import ContactUsMessageController from "./Controllers/ContactUsMessageController";
import ContactUsNameController from "./Controllers/ContactUsNameController";
import ContactUsSurnameController from "./Controllers/ContactUsSurnameController";

const ContactUsForm: FC = () => {
  const { control } = useContactUsForm();
  const { errors } = useContactUsFormState({ control });

  return (
    <div className={styles.container}>
      <div className={styles.nameAndSurnameWrapper}>
        <div className={styles.desktopText}>
          <ContactUsNameController control={control} errors={errors} />
        </div>
        <div className={styles.desktopText}>
          <ContactUsSurnameController control={control} errors={errors} />
        </div>
      </div>
      <ContactUsEmailController control={control} errors={errors} />
      <ContactUsMessageController control={control} errors={errors} />
      <div className={styles.privacyCheck}>
        <FormPrivacyController
          nameField="privacyAccepted"
          control={control}
          errors={errors.privacyAccepted?.types}
          testId="registration-form-privacy-checkbox"
        />
        <FormReCaptchaController
          nameField="recaptcha"
          testId="contactUs-form"
          control={control}
          errors={errors.recaptcha?.types}
        />
      </div>
    </div>
  );
};

export default ContactUsForm;
