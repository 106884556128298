import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface ContactUsStore {
  isContactUsModalOpen: boolean;
  openContactUsModal: () => void;
  closeContactUsModal: () => void;
}

const useContactUsStore = create<ContactUsStore>()(
  devtools((set, get) => ({
    isContactUsModalOpen: false,
    openContactUsModal: () => set({ isContactUsModalOpen: true }),
    closeContactUsModal: () => set({ isContactUsModalOpen: false }),
  }))
);

export default useContactUsStore;
