import {
  CASE_STEP_FOUR_SURVEY_LINK,
  DASHBOARD_CONFIG_PREFIX,
  MODULE_CONFIG,
} from "./constants";
import { getFirestoreSystemConfig } from "./firestore";
import { type Role } from "./types/Role";
import {
  type DashboardRoleSystemConfig,
  type ModuleSystemConfig,
} from "./types/SystemConfig";

export const getModuleConfig = async () =>
  await getFirestoreSystemConfig<ModuleSystemConfig>(MODULE_CONFIG);

export const getDashboardConfig = async (roleId: Role["id"]) =>
  await getFirestoreSystemConfig<DashboardRoleSystemConfig>(
    `${DASHBOARD_CONFIG_PREFIX}_${roleId}`
  );

export const getCaseStepFourSurveyLink = async () =>
  await getFirestoreSystemConfig<{ id: string; link: string }>(
    CASE_STEP_FOUR_SURVEY_LINK
  );
