import { redirect } from "react-router-dom";

import { isUserLoggedIn } from "@/firebase/auth";
import Pages from "@/pages/router-pages";
import useUserStore from "@/store/useUserStore";

const authorizedLoader = async () => {
  const logged = await isUserLoggedIn();
  if (!logged) {
    return redirect(Pages.LOGIN);
  }

  try {
    await useUserStore.getState().fulfillUserState();
  } catch (err) {
    console.error(err);
  }
  return null;
};

export default authorizedLoader;
