import { callApi } from "@/api/apiClient";
import { type AssistantData } from "@/api/assistant/types";
import { CHECK_ASSISTANT_API } from "@/api/constants";
import { type Case } from "@/firebase/types/Case";

export const checkAssistant = async (
  caseId: Case["id"],
  token: string
): Promise<AssistantData> =>
  await callApi({
    method: "GET",
    url: `/${CHECK_ASSISTANT_API}`,
    params: {
      caseId,
      token,
    },
  });
