import { type MultipleFieldErrors } from "react-hook-form/dist/types/errors";

import { DEFAULT_EMPTY_STRING } from "@/utils/utilityConstants";

import HelpMessage from "../HelpMessage";

export interface InputMultiValidationProps {
  testId?: string;
  errors?: MultipleFieldErrors;
}

const MultiValidation = ({
  testId = "multi-validation",
  errors = {},
}: InputMultiValidationProps) => {
  const arrayErrorsKeys = Object.keys(errors);

  if (!arrayErrorsKeys.length) return null;

  return (
    <div className="flex flex-col gap-1">
      {arrayErrorsKeys.map((objectKey, i) => (
        <div className="flex items-center" key={objectKey}>
          <HelpMessage
            testId={`${testId}-error`}
            variant="invalid"
            label={errors[objectKey]?.toString() ?? DEFAULT_EMPTY_STRING}
          />
        </div>
      ))}
    </div>
  );
};

export default MultiValidation;
