import classNames from "classnames";

import InputContainer, {
  type InputContainerProps,
} from "@/components/Atoms/InputContainer";
import { DEFAULT_TEXTAREA_ROWS } from "@/theme/theme-constants";
import { DEFAULT_EMPTY_STRING } from "@/utils/utilityConstants";

import styles from "./TextArea.module.scss";

export type TextAreaProps = {
  value?: string | null;
  isDisabled?: boolean;
  isInvalid?: boolean;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  /**
   * Props della TestArea
   */
  textAreaProps?: JSX.IntrinsicElements["textarea"];
  onChange?: JSX.IntrinsicElements["textarea"]["onChange"];
  rows?: number;
  textAreaTestId?: string;
} & InputContainerProps;

const TextArea = ({
  title = DEFAULT_EMPTY_STRING,
  titleTestId,
  value = DEFAULT_EMPTY_STRING,
  onChange,
  textAreaProps: {
    required,
    onChange: onChangeTextAreaProp,
    ...textAreaProps
  } = {},
  rows = DEFAULT_TEXTAREA_ROWS,
  titleProps,
  isDisabled: isDisabledProp = false,
  validationData: { errors, testId: errorMessageTestId } = {},
  isInvalid = false,
  helpMessage = "",
  leftIcon,
  rightIcon,
  textAreaTestId,
  helpMessageTestId,
}: TextAreaProps) => {
  const isInvalidInternal = errors
    ? !Object.values(errors).every((err) => err === true)
    : isInvalid;
  const isDisabled = textAreaProps?.disabled ?? isDisabledProp;

  const textAreaWrapperStyles = classNames(
    [styles.textAreaWrapper, textAreaProps?.className],
    {
      [styles.disabledWrapper]: isDisabled,
      [styles.invalidWrapper]: isInvalidInternal,
    }
  );

  const textAreaStyles = classNames([styles.textArea]);

  return (
    <InputContainer
      validationData={{
        errors,
        testId: errorMessageTestId,
      }}
      isDisabled={isDisabled}
      title={title}
      isRequired={required}
      titleProps={titleProps}
      titleTestId={titleTestId}
      isInvalid={isInvalidInternal}
      helpMessage={helpMessage}
      helpMessageTestId={helpMessageTestId}
    >
      <div className={textAreaWrapperStyles}>
        {leftIcon}
        <textarea
          value={value ?? ""}
          onChange={onChange}
          disabled={isDisabled}
          className={textAreaStyles}
          data-test-id={textAreaTestId}
          rows={rows}
          required={required}
          {...textAreaProps}
        >
          {value}
        </textarea>
        {rightIcon}
      </div>
    </InputContainer>
  );
};

export default TextArea;
