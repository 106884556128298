import { type FC } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import InputText from "@/components/Atoms/Input";
import { type ContactUsFormControllerProps } from "@/components/ContactUsModal/types";
import { EMAIL_REGEX } from "@/utils/utilityConstants";

const ContactUsEmailController: FC<ContactUsFormControllerProps> = ({
  control,
  errors,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="email"
      render={({
        field: { disabled, onChange, onBlur, value, ...inputProps },
      }) => (
        <InputText
          title={t("baseUser.table.email")}
          value={value}
          onChange={onChange}
          inputProps={{
            required: true,
            placeholder: t(`delegates.list.invite.form.email.placeholder`),
            onBlur: () => onChange(value?.trim()),
            ...inputProps,
          }}
          inputTestId="contactUs-form-email"
          validationData={{
            errors: errors?.email?.types,
            testId: "contactUs-form-email-validation",
          }}
        />
      )}
      rules={{
        required: t("generic.field.validation.required"),
        pattern: {
          value: EMAIL_REGEX,
          message: t("generic.field.validation.pattern"),
        },
      }}
    />
  );
};

export default ContactUsEmailController;
