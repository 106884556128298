import classNames from "classnames";
import { type FC, useMemo } from "react";

import {
  TypographyBody,
  TypographyBodySmall,
} from "@/components/Foundations/Typography";
import MultiValidation, {
  type InputMultiValidationProps,
} from "@/components/Molecules/MultiValidation";
import { isStringNotEmpty } from "@/utils/typeCheck";
import { DEFAULT_EMPTY_STRING } from "@/utils/utilityConstants";

import styles from "./MultiSelectButton.module.scss";

export interface MultiSelectButtonProps {
  children?: JSX.Element;
  label?: string | JSX.Element;
  disabled?: boolean;
  testId?: string;
  isInvalid?: boolean;
  title?: string;
  required?: boolean;
  validationData?: InputMultiValidationProps;
}

const MultiSelectButton: FC<MultiSelectButtonProps> = ({
  children,
  label,
  disabled,
  testId,
  isInvalid = false,
  title = DEFAULT_EMPTY_STRING,
  required = false,
  validationData: { errors, testId: errorMessageTestId } = {},
}) => {
  const isInvalidInternal = errors
    ? !Object.values(errors).every((err) => err === true)
    : isInvalid;

  const labelStyle = classNames([
    {
      [styles.labelDisabled]: disabled,
    },
  ]);

  const titleMemo = useMemo(() => {
    if (!title) return;
    if (required) return `${title} *`;
    return title;
  }, [required, title]);

  return (
    <div className="flex flex-col gap-1">
      {!!title && (
        <TypographyBodySmall
          value={titleMemo}
          variant="medium"
          className="text-medics-text-dark-grey"
        />
      )}
      <div className={styles.multiSelectContainer} data-test-id={testId}>
        {children}
        {label &&
          (isStringNotEmpty(label) ? (
            <TypographyBody value={label} className={labelStyle} />
          ) : (
            label
          ))}
      </div>
      {isInvalidInternal && (
        <MultiValidation errors={errors} testId={errorMessageTestId} />
      )}
    </div>
  );
};

export default MultiSelectButton;
