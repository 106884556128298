import { type TypographyValue, type TypographyVariantObject } from "..";

/**
 * Componente tipografia per bottoni
 *
 **/

type TypographyButtonProps = JSX.IntrinsicElements["p"] &
  TypographyVariantObject<"medium" | "bold"> &
  TypographyValue;

const TypographyButton = ({
  value = "",
  variant = "medium",
  className = "",
  testId,
  ...props
}: TypographyButtonProps) => (
  <span
    className={`text-button font-${variant} ${className}`}
    data-test-id={testId}
    {...props}
  >
    {value}
  </span>
);

export default TypographyButton;
