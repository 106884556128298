import ContactUsModal from "@/components/ContactUsModal";
import { ContactUsFormProvider } from "@/components/ContactUsModal/form";
import useContactUsModal from "@/components/ContactUsModal/hooks/useContactUsModal";
import { DEFAULT_EMPTY_STRING } from "@/utils/utilityConstants";

const ContactUsModalForm = () => {
  const { isContactUsModalOpen } = useContactUsModal();

  if (!isContactUsModalOpen) return null;

  return (
    <ContactUsFormProvider
      defaultValues={{
        name: DEFAULT_EMPTY_STRING,
        surname: DEFAULT_EMPTY_STRING,
        email: DEFAULT_EMPTY_STRING,
        message: DEFAULT_EMPTY_STRING,
        privacyAccepted: false,
        recaptcha: DEFAULT_EMPTY_STRING,
      }}
    >
      <ContactUsModal />
    </ContactUsFormProvider>
  );
};

export default ContactUsModalForm;
