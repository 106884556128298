import { type InsertUser } from "@/firebase/types/User";

import { callApi } from "./apiClient";
import { USER_CREATE_API } from "./constants";

export const userCreate = async (newUser: InsertUser) =>
  await callApi<string>({
    method: "POST",
    url: `/${USER_CREATE_API}`,
    data: {
      ...newUser,
    },
  });
