import { type LoaderFunctionArgs, redirect } from "react-router-dom";

import Pages from "@/pages/router-pages";

const signupLoader = async ({ request: { url } }: LoaderFunctionArgs) => {
  const urlObj = new URL(url);
  const invitationId = urlObj.searchParams.get("invitationId");
  const token = urlObj.searchParams.get("token");
  const delegateListId = urlObj.searchParams.get("delegateListId");
  if (!invitationId && !token && !delegateListId) {
    return redirect(Pages.LOGIN);
  }

  return null;
};

export default signupLoader;
