import { useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, type FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

import InputContainer from "@/components/Atoms/InputContainer";
import { type EntityFormControllerProps } from "@/entity/types";
import { RECAPTCHA_SITE_KEY } from "@/utils/utilityConstants";

const FormReCaptchaController = <TForm extends FieldValues>({
  nameField,
  testId = "form",
  control,
  errors,
}: EntityFormControllerProps<TForm>) => {
  useEffect(() => {
    if (
      errors &&
      Object.hasOwn(errors, "recaptcha/timeout-or-duplicate") &&
      refReCAPTCHA.current
    ) {
      refReCAPTCHA.current.reset();
    }
  }, [errors]);

  const refReCAPTCHA = useRef<ReCAPTCHA>(null);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <Controller
      name={nameField}
      control={control}
      render={({
        field: { disabled, onChange, onBlur, value, ...inputProps },
      }) => (
        <InputContainer
          validationData={{ errors, testId: `${testId}-recaptcha-validation` }}
          isInvalid={!!errors}
        >
          <ReCAPTCHA
            //* Force re-render on language changes
            key={language}
            hl={language}
            sitekey={RECAPTCHA_SITE_KEY}
            onChange={onChange}
            id={`${testId}-g-recaptcha`}
            ref={refReCAPTCHA}
          />
        </InputContainer>
      )}
      rules={{
        required: t("recaptcha.toast.error.description"),
      }}
    />
  );
};

export default FormReCaptchaController;
