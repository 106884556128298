import classNames from "classnames";
import React, { useMemo } from "react";

import { type BaseButtonProps } from "@/components/Atoms/Button/types";
import Spinner from "@/components/Atoms/Spinner";
import { DEFAULT_EMPTY_STRING } from "@/utils/utilityConstants";

import styles from "./Button.module.scss";
import { getButtonSizeVariantComponent } from "./utils/button-size-variants";

export interface ButtonProps extends BaseButtonProps {
  variant?:
    | "primary"
    | "secondary"
    | "success"
    | "secondary-dark"
    | "warning"
    | "sidebar"
    | "danger";
  labelClassNames?: string;
  size?: "extra-small" | "small" | "medium" | "large";
  isReadonly?: boolean;
  isFullWidth?: boolean;
  testId?: string;
}

const Button = ({
  buttonProps: {
    className = DEFAULT_EMPTY_STRING,
    disabled = false,
    ...buttonProps
  } = {},
  label,
  labelClassNames = DEFAULT_EMPTY_STRING,
  leftIcon,
  rightIcon,
  variant = "primary",
  size = "medium",
  alignment = "center",
  isLoading = false,
  isLoadingCursor = false,
  isActive = false,
  isReadonly = false,
  isFullWidth = false,
  testId,
}: ButtonProps) => {
  const ButtonTypography = getButtonSizeVariantComponent(size);
  const buttonStyles = classNames([
    styles.buttonContainer,
    styles[variant],
    styles[size],
    styles[alignment],
    className,
    {
      [styles.active]: isActive,
      [styles.withIcon]: !!leftIcon || !!rightIcon,
      [styles.onlyIcon]: (!!leftIcon || !!rightIcon) && !label,
      [styles.readonly]: isReadonly,
      [styles.isLoadingCursor]: isLoadingCursor,
      "w-full": isFullWidth || variant === "sidebar",
    },
  ]);

  const buttonLabelStyles = classNames([styles.label, labelClassNames]);

  const buttonComponent = useMemo(
    () => (
      <button
        className={buttonStyles}
        data-test-id={testId}
        {...buttonProps}
        disabled={disabled || isLoading}
      >
        {isLoading ? <Spinner size="small" /> : leftIcon}
        {label && (
          <ButtonTypography
            className={buttonLabelStyles}
            variant="medium"
            value={label}
          />
        )}
        {rightIcon}
      </button>
    ),
    [
      ButtonTypography,
      buttonLabelStyles,
      buttonProps,
      buttonStyles,
      disabled,
      isLoading,
      label,
      leftIcon,
      rightIcon,
      testId,
    ]
  );

  return buttonComponent;
};
export default Button;
