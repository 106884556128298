export const CASES_API = "api/medical-cases";
export const MOCK_API = "api/test/mockTestData";
export const MOCK_DEMO_WITH_CASE_API = "api/test/mockDemoWithCaseTestData";
export const MOCK_USER_TEST_DATA_API = "api/test/mockUserTestData";
export const MOCK_AUTH_USER_TEST_DATA_API = "api/test/mockAuthUserTestData";
export const CHANGE_CASES_STATUS_API = "medicalCaseChangeStatus";
export const FORCE_CASE_CLOSING_API = "forceCaseClosing";
export const CONFIRM_THREED_PRINT_REVIEW_API = "confirmThreedPrintReview";
export const INVITE_ASSISTANT_API = "inviteAssistant";
export const DECRYPT_PATH = "decrypt";
export const CHANGE_USER_STATUS_API = "changeUserStatus";
export const REJECT_USER_STATUS_API = "rejectUser";
export const FORCE_RESET_PASSWORD_STATUS_API = "forceResetPassword";
export const VERIFIED_USER_API = "verifiedUser";
export const MOCK_ORDERS_TEST_DATA = "api/test/mockOrdersTestData";
export const MOCK_ORGANIZATIONS_TEST_DATA = "api/test/mockOrganizationTestData";
export const DELETE_ORDER = "deleteOrder";
export const CHECK_ASSISTANT_API = "checkAssistant";
export const SUBMIT_CASE_ASSISTANT_API = "submitCaseAssistant";
export const DELETE_ORGANIZATION = "deleteOrganization";
export const MEDICS_ID_EDIT_API = "medicsIdEdit";
export const DOCTOR_LIST_API = "doctorList";
export const USER_SIGNUP_API = "userSignup";
export const USER_CREATE_API = "userCreate";
export const WRITE_DICOM_DOCUMENT_API = "writeDicomDocument";
export const CREATE_GET_DELEGATE_LIST_API = "createOrGetDelegateList";
export const GET_DELEGABLE_USER_LIST_API = "delegableUserList";
export const MOCK_DELEGATES_TEST_DATA = "api/test/mockDelegatesTestData";
export const MOCK_DELEGATES_EMPTY_TEST_DATA =
  "api/test/mockDelegatesEmptyTestData";
export const ADD_CASE_PMS_API = "addCasePms";
export const UPDATE_CASE_SHIPPING_ADDRESS_API = "updateCaseShippingAddress";
export const CUSTOM_VIEWER_TOKEN_API = "api/auth/getCustomToken";
export const USER_DELETE_REQUEST_API = "userDeleteRequest";
export const ADD_INVITE_DELEGATE = "addOrInviteDelegate";
export const DOCUMENT_UPLOADED_API = "documentUploaded";
export const DELETE_DELEGATE_INVITATION = "deleteDelegateInvitation";
export const DELETE_DELEGATE = "deleteDelegate";
export const MOCK_RESET_USER_NOTIFICATION_TEST_DATA =
  "api/test/mockResetUserNotificationTestData";
export const SEND_NOTIFICATION_MESSAGES_API = "sendNotificationMessages";
export const MOCK_GUIDELINES_TEST_DATA = "api/test/mockGuidelinesTestData";
export const VALID_ORDER_LIST_API = "getValidOrderList";
export const UPDATE_CASE_ORDER_API = "updateCaseOrder";
export const NOTIFICATION_AS_READ_API = "notificationAsRead";
export const CHECK_DELEGATE_INVITATION_API = "checkDelegateInvitation";
export const GET_DEMO_CASES_API = "getDemoCases";
export const GET_CASES_FOR_DEMO_USER_API = "getCasesForDemoUser";
export const RESEND_VERIFICATION_LINK_API = "resendVerificationLink";
export const DELETE_DEMO_USER_API = "deleteDemoUser";
export const SEND_RESET_PASSWORD_LINK_API = "sendResetPasswordLink";
export const EXTEND_PASSWORD_EXPIRATION_AFTER_RESET_PASSWORD_API =
  "extendPasswordExpirationAfterResetPassword";
