import classNames from "classnames";
import {
  type ReactNode,
  type SyntheticEvent,
  useCallback,
  useEffect,
} from "react";
import { createPortal } from "react-dom";
import { RiArrowLeftLine, RiCloseLine } from "react-icons/ri";

import Button, { type ButtonProps } from "@/components/Atoms/Button";
import TextButton, {
  type TextButtonProps,
} from "@/components/Atoms/TextButton";
import { type CardHeaderProps } from "@/components/Molecules/CardHeader";
import Card from "@/components/Organisms/Card";
import useModalRef from "@/hooks/useModalRef";
import i18n from "@/i18n";
import { DEFAULT_BUTTON_ICON_SIZE } from "@/theme/theme-constants";

import styles from "./Modal.module.scss";

export interface ModalProps {
  isOpen: boolean;
  Header: CardHeaderProps & {
    onClose?: () => void;
    onBack?: () => void;
    onBackLabel?: string;
  };
  Subtitle?: JSX.Element;
  isFullScreen?: boolean;
  variant?: "light" | "dark";
  Footer?: {
    primaryActionsButtonsProps?: ButtonProps[];
    secondaryActionsTextButtonProps?: TextButtonProps[];
  };
  children?: JSX.Element | ReactNode;
  testId?: string;
}

const Modal = ({
  isOpen,
  children,
  Subtitle,
  isFullScreen = false,
  variant = "light",
  testId,
  Header: {
    onClose,
    onBack,
    onBackLabel = i18n.t("generic.navigation.back"),
    title,
    icon,
    children: HeaderChildren,
  } = {
    title: "",
  },
  Footer: {
    primaryActionsButtonsProps = [],
    secondaryActionsTextButtonProps = [],
  } = {},
}: ModalProps) => {
  const { ref: dialogRef, open, close } = useModalRef();

  const onCancelHandler = useCallback(
    (e: SyntheticEvent<HTMLDialogElement>) => {
      e.preventDefault();
      onClose?.();
    },
    [onClose]
  );

  useEffect(() => {
    if (isOpen && !dialogRef.current?.open) open();
    else close();

    return () => close();
  }, [close, dialogRef, isOpen, open]);

  const dialogStyle = isOpen
    ? classNames([
        styles.dialog,
        {
          [styles.fullScreen]: isFullScreen,
          [styles.backdrop]: !isFullScreen,
        },
      ])
    : "";

  const wrapperStyle = classNames([
    styles.wrapper,
    {
      [styles.fullScreen]: isFullScreen,
    },
  ]);

  const bodyStyle = classNames([
    styles.body,
    {
      [styles.fullScreen]: isFullScreen,
    },
  ]);

  return createPortal(
    <dialog
      ref={dialogRef}
      className={dialogStyle}
      onCancel={onCancelHandler}
      data-test-id={testId}
    >
      {isOpen && (
        <div className={wrapperStyle}>
          <Card
            withModal
            variant={variant}
            Header={
              <Card.Header
                variant={variant}
                mobileActionsTop
                title={title}
                icon={icon}
              >
                <div className={styles.cardHeaderActionsContainer}>
                  <div className={styles.backIconContainer}>
                    {onBack && (
                      <TextButton
                        buttonProps={{
                          onClick: onBack,
                        }}
                        variant="tertiary"
                        testId={`${testId ?? "modal"}-modal-exit`}
                        leftIcon={
                          <RiArrowLeftLine
                            size={DEFAULT_BUTTON_ICON_SIZE}
                            className="cursor-pointer"
                          />
                        }
                        label={onBackLabel}
                      />
                    )}
                  </div>

                  {onClose && (
                    <TextButton
                      size="large"
                      buttonProps={{
                        onClick: onClose,
                      }}
                      testId={`${testId ?? "modal"}-modal-close`}
                      leftIcon={
                        <RiCloseLine
                          size={DEFAULT_BUTTON_ICON_SIZE}
                          className="cursor-pointer"
                        />
                      }
                    />
                  )}
                  {HeaderChildren}
                </div>
              </Card.Header>
            }
            Footer={
              !!primaryActionsButtonsProps.length ||
              !!secondaryActionsTextButtonProps.length ? (
                <Card.Footer variant={variant}>
                  <div className={styles.footer}>
                    {secondaryActionsTextButtonProps.map(
                      (secondaryActionButtonProps, index) => (
                        <TextButton
                          key={`${
                            title ?? "text-button"
                          }-modal-secondary-action-text-button-${index}`}
                          {...secondaryActionButtonProps}
                        />
                      )
                    )}
                    {primaryActionsButtonsProps.map(
                      (primaryActionButtonProps, index) => (
                        <Button
                          key={`${
                            title ?? "button"
                          }-modal-primary-action-button-${index}`}
                          {...primaryActionButtonProps}
                        />
                      )
                    )}
                  </div>
                </Card.Footer>
              ) : null
            }
          >
            <div className={bodyStyle}>
              {Subtitle}
              {children}
            </div>
          </Card>
        </div>
      )}
    </dialog>,
    document.body
  );
};

export default Modal;
