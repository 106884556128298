import { type FC } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import TextArea from "@/components/Atoms/TextArea";
import { type ContactUsFormControllerProps } from "@/components/ContactUsModal/types";

const ContactUsMessageController: FC<ContactUsFormControllerProps> = ({
  control,
  errors,
}) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name="message"
      render={({
        field: { disabled, onChange, onBlur, value, ...inputProps },
      }) => (
        <TextArea
          title={t("case.modal.SUBMITTED.form.message.title")}
          value={value}
          validationData={{
            errors: errors?.message?.types,
            testId: "contactUs-form-message-validation",
          }}
          textAreaProps={{
            placeholder: t("case.modal.SUBMITTED.form.message.placeholder"),
            onBlur: () => onChange(value?.trim()),
            required: true,
            ...inputProps,
          }}
          onChange={onChange}
        />
      )}
      rules={{
        required: t("generic.field.validation.required"),
      }}
    />
  );
};

export default ContactUsMessageController;
