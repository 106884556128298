import { type TypographyValue, type TypographyVariantObject } from "..";

export type TypographyBodySmallProps = JSX.IntrinsicElements["span"] &
  TypographyVariantObject<"normal" | "medium" | "semibold"> &
  TypographyValue;

const TypographyBodySmall = ({
  value = "",
  variant = "normal",
  className = "",
  testId,
  ...props
}: TypographyBodySmallProps) => (
  <span
    className={`text-body-small font-${variant} ${className}`}
    data-test-id={testId}
    {...props}
  >
    {value}
  </span>
);

export default TypographyBodySmall;
