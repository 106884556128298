import classNames from "classnames";
import {
  type CSSProperties,
  forwardRef,
  type ForwardRefRenderFunction,
} from "react";
import { RiCloseLine } from "react-icons/ri";

import TextButton from "@/components/Atoms/TextButton";
import {
  TypographyBody,
  TypographyBodySmall,
} from "@/components/Foundations/Typography";
import { isStringNotEmpty } from "@/utils/typeCheck";

import styles from "./Alert.module.scss";

export interface AlertProps {
  leftIcon?: JSX.Element;
  onClose?: () => void;
  withoutArrow?: boolean;
  title?: string;
  description?: string | JSX.Element;
  progressValue?: number;
  maxProgressValue?: number;
  size?: "full" | "boxed";
  variant?: "general" | "info" | "warning" | "danger" | "success";
  isPaddingSmall?: boolean;
  position?: "default" | "top" | "right" | "bottom" | "left";
  testId?: string;
  alertStyles?: CSSProperties;
}

const Alert: ForwardRefRenderFunction<HTMLDivElement, AlertProps> = (
  {
    leftIcon,
    onClose,
    withoutArrow = false,
    title,
    description,
    size = "full",
    variant = "info",
    isPaddingSmall = false,
    progressValue,
    maxProgressValue = 100,
    position = "default",
    alertStyles,
    testId,
  },
  ref
) => {
  const alertContainerStyles = classNames(
    [styles.alertContainer, styles[size], styles[variant], styles[position]],
    {
      [styles.isPaddingSmall]: isPaddingSmall,
      [styles.withoutArrow]: withoutArrow,
    }
  );

  return (
    <div
      ref={ref}
      className={alertContainerStyles}
      data-test-id={testId}
      style={alertStyles}
    >
      {leftIcon && <div className={styles.iconWrapper}>{leftIcon}</div>}

      <div className={styles.textWrapper}>
        {!!title && <TypographyBody variant="semibold" value={title} />}

        {isStringNotEmpty(description) ? (
          <TypographyBodySmall variant="medium" value={description} />
        ) : (
          description
        )}
      </div>

      {onClose && (
        <TextButton
          buttonProps={{
            className: classNames([styles.iconWrapper, styles.closeIcon]),
            onClick: onClose,
          }}
          leftIcon={<RiCloseLine />}
        />
      )}

      {progressValue !== undefined && (
        <div className={styles.progressWrapper}>
          <progress
            className={styles.progress}
            value={progressValue}
            max={maxProgressValue}
          />
        </div>
      )}
    </div>
  );
};

export default forwardRef(Alert);
