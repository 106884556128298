import i18n from "i18next";
import { RiCheckboxCircleLine, RiCloseCircleLine } from "react-icons/ri";
import { toast, type ToastOptions } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import Spinner from "@/components/Atoms/Spinner";
import Toast, { type ToastProps } from "@/components/Molecules/Toast";
import { DEFAULT_TOAST_AUTO_CLOSE_TIME } from "@/theme/theme-constants";

const defaultToastOptions: ToastOptions = {
  autoClose: DEFAULT_TOAST_AUTO_CLOSE_TIME,
  closeOnClick: false,
};

export const invokeToast = (
  toastProps: ToastProps,
  toastOptions: ToastOptions = defaultToastOptions
) => {
  const toastId = toastOptions.toastId?.toString() ?? uuidv4();
  return toast(<Toast {...toastProps} toastId={toastId} />, {
    ...defaultToastOptions,
    ...toastOptions,
    toastId,
    hideProgressBar: true,
    closeButton: false,
  });
};

type ToastPropsMutation = Omit<ToastProps, "variant" | "leftIcon"> & {
  description: Required<ToastProps["description"]>;
};

export const invokeToastError = (
  toastPropsMutation: ToastPropsMutation,
  toastOptions: ToastOptions = defaultToastOptions
) =>
  invokeToast(
    {
      title: i18n.t(`generic.danger`),
      ...toastPropsMutation,
      leftIcon: <RiCloseCircleLine />,
      variant: "danger",
    },
    toastOptions
  );

export const invokeToastLoading = (
  toastPropsMutation: ToastPropsMutation,
  toastOptions: ToastOptions = defaultToastOptions
) =>
  invokeToast(
    {
      title: i18n.t(`generic.loading`),
      ...toastPropsMutation,
      leftIcon: <Spinner size="small" />,
      variant: "info",
    },
    toastOptions
  );

export const invokeToastSuccess = (
  toastPropsMutation: ToastPropsMutation,
  toastOptions: ToastOptions = defaultToastOptions
) =>
  invokeToast(
    {
      title: i18n.t(`generic.success`),
      ...toastPropsMutation,
      leftIcon: <RiCheckboxCircleLine />,
      variant: "success",
    },
    toastOptions
  );
