import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { type Organization } from "@/firebase/types/Organization";
import { type Specialization } from "@/firebase/types/Specialization";

export interface FirestoreDataState {
  specializations: Specialization[];
  organizations: Organization[];
  setOrganizations: (organizations: Organization[]) => void;
  setSpecializations: (specializations: Specialization[]) => void;
}

const useFirestoreData = create<FirestoreDataState>()(
  devtools((set) => ({
    specializations: [],
    organizations: [],
    setOrganizations: (organizations) =>
      set(() => ({
        organizations,
      })),
    setSpecializations: (specializations) =>
      set(() => ({
        specializations,
      })),
  }))
);

export default useFirestoreData;
