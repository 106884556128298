export const DEFAULT_ICON_SIZE = 24;
export const DEFAULT_BUTTON_ICON_SIZE = 20;
export const DEFAULT_UPLOAD_ICON_SIZE = 56;
export const HELP_MESSAGE_ICON_SIZE = 16;
export const STATUS_LABEL_ICON_SIZE = 20;
export const TABLE_ICON_SIZE = 16;
export const DEFAULT_TEXTAREA_ROWS = 6;

export const THEME_NUMBER_BREAKPOINT = {
  "2xs": 375,
  xs: 480,
  sm: 640,
  md: 834,
  lg: 1280,
  xl: 1920,
} as const;

export const DEFAULT_TOAST_AUTO_CLOSE_TIME = 5000;
