import { captureException, withScope } from "@sentry/react";
import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";

import { DEFAULT_USE_QUERY_SETTINGS } from "./utils/utilityConstants";
import { JSONStringifyFormatted } from "./utils/utilityFunction";

export const applicationQueryClient = new QueryClient({
  defaultOptions: {
    queries: DEFAULT_USE_QUERY_SETTINGS,
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      console.error(error);
      withScope((scope) => {
        scope.setExtras({});
        captureException(error, {
          extra: { jsonFormatted: JSONStringifyFormatted({ error, query }) },
        });
      });
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, variables, context, mutation) => {
      console.error(error);
      withScope((scope) => {
        captureException(error, {
          extra: {
            jsonFormatted: JSONStringifyFormatted({
              error,
              variables,
              context,
              mutation,
            }),
          },
        });
      });
    },
  }),
});
