import { type Control } from "react-hook-form";

import { DEFAULT_EMPTY_STRING } from "@/utils/utilityConstants";

export const isString = (value: unknown): value is string =>
  typeof value === "string";

type NonEmptyString = string & { __nonEmptyString: never };

export const isStringNotEmpty = (value: unknown): value is NonEmptyString =>
  typeof value === "string" && value.trim() !== DEFAULT_EMPTY_STRING;

export const isEmpty = <T>(value: T[] | null | undefined) =>
  !value || value.length === 0;

export const isFunction = (value: unknown): value is (...args: any[]) => any =>
  typeof value === "function";

export const instanceOf = <T extends object>(
  object: any,
  key: keyof T
): object is T => {
  return key in object;
};

export type DeepKeyOf<T> = T extends object
  ? {
      [K in keyof T]-?: DeepKeyOf<T[K]> extends ""
        ? `${K & string}`
        : `${K & string}.${DeepKeyOf<T[K]>}`;
    }[keyof T]
  : "";

// REF: https://stackoverflow.com/a/58436959
type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${"" extends P ? "" : "."}${P}`
    : never
  : never;

type Prev = [
  never,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  ...Array<0>
];

export type Paths<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
  ? {
      [K in keyof T]-?: K extends string | number
        ? `${K}` | Join<K, Paths<T[K], Prev[D]>>
        : never;
    }[keyof T]
  : "";

export type Leaves<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
  ? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
  : "";

// REF: https://stackoverflow.com/a/71353081
//* Replace all occurrences of `S` in `T` with `D`
export type Replace<
  T extends string, // La stringa originale in cui vogliamo effettuare la sostituzione.
  S extends string, // La sotto stringa da cercare e sostituire.
  D extends string, // La sotto stringa con cui sostituire S.
  A extends string = "" // La stringa da aggiungere prima della sostituzione (predefinita: stringa vuota).
> = T extends `${infer L}${S}${infer R}` // Controlla se T contiene S.
  ? Replace<R, S, D, `${A}${L}${D}`> // Se S è trovato, esegue una sostituzione ricorsiva.
  : `${A}${T}`; // Se S non è più presente, restituisce la stringa risultante.

// Esempi di utilizzo:
// Replace<"hello world", "world", "there"> restituirà "hello there".
// Replace<"foo foo bar foo", "foo", "baz"> restituirà "baz baz bar baz".

export type GetControlT<C extends Control<any>> = C extends Control<infer T>
  ? T
  : unknown;
