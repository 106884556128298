import i18next from "i18next";

import { type BlockerNavigationModalConfig } from "./types";

export const evalGenericBlockerNavigationConfig: () => BlockerNavigationModalConfig =
  () => ({
    key: "",
    shouldBlockNavigation: false,
    title: i18next.t("generic.layout.exitModal.title"),
    info: i18next.t("generic.layout.exitModal.info"),
    proceed: i18next.t("generic.layout.exitModal.button.proceed"),
    reset: i18next.t("generic.layout.exitModal.button.reset"),
  });
